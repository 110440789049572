import { NgModule } from "@angular/core";
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RcAppComponent } from "./app.component";
import { RcNotFoundComponent } from "./rc-widgets/rc-notfound/rc-notfound.component";

export const appRoutes: Routes = [
  {
    path: "org",
    loadChildren: () =>
      import("./rc-apps/org/org.module").then((m) => m.RcOrgAppModule),
  },
  {
    path: "create",
    loadChildren: () =>
      import("./rc-apps/org-setup/org-setup.module").then(
        (m) => m.RcOrgSetupModule
      ),
  },
  {
    path: "par",
    component: RcAppComponent,
    resolve: {
        url: 'externalUrlRedirectResolver'
    },
    data: {
        externalUrl: '/app2'
    }
  },
  {
    path: "join",
    component: RcAppComponent,
    resolve: {
        url: 'externalUrlRedirectResolver'
    },
    data: {
        externalUrl: '/app2/join'
    }
  },

  // Make sure these ALWAYS stays at the end
  { path: "", component: RcAppComponent },
  // { path: "app", redirectTo: "/" }, // This is for local testing,
  // { path: "404", component: RcNotFoundComponent }, // 404 Handler
  // { path: "sentry-test/ng", component: RcSentryTestComponent }, // Angular Sentry Testing
  { path: "**", component: RcNotFoundComponent },
];

@NgModule({
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        {
            window.location.href = (route.data as any).externalUrl;
        }
    }
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class RcAppRoutingModule {}
