import { HAMMER_GESTURE_CONFIG, HammerModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgBusyModule, BusyConfig } from "ng-busy";
import { FileUploadModule } from "ng2-file-upload";
import { NouisliderModule } from "ng2-nouislider";
import { NgxSliderModule } from 'ngx-slider-v2';
import { ToastrModule } from "ngx-toastr";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { RcErrorComponent } from "./rc-error/rc-error.component";
import { RcFooterComponent } from "./rc-footer/rc-footer.component";
import { RcSearchListComponent } from "./rc-search-list/rc-search-list.component";
import { RcLoaderComponent } from "./rc-loader/rc-loader.component";
import { RcNotAuthorizedComponent } from "./rc-not-authorized/rc-not-authorized.component";
import { RcSelectFieldComponent } from "./rc-select-field/rc-select-field.component";
import { RcControlMessagesComponent } from "./rc-control-messages/rc-control-messages.component";
import { RcControlMessagesService } from "./rc-control-messages/rc-control-messages.service";
import { RcFieldComponent } from "./rc-field/rc-field.component";
import { RcTextFieldComponent } from "./rc-text-field/rc-text-field.component";
import { RcTypeaheadFieldComponent } from "./rc-typeahead/rc-typeahead-field.component";
import { RcSwitchFieldComponent } from "./rc-switch-field/rc-switch-field.component";
import { RcDateFieldComponent } from "./rc-date-field/rc-date-field.component";
import { RcSortableComponent } from "./rc-sortable/rc-sortable.component";
import { RcSortableDirective } from "./rc-sortable/rc-sortable.directive";
import { RcSortableService } from "./rc-sortable/rc-sortable.service";
import { RcBusyGuardService } from "./rc-guards/rc-busy-guard.service";
import { RcUnloadGuardService } from "./rc-guards/rc-unload-guard.service";
import { RcResizingCroppingImagesComponent } from "./rc-img-cropper/rc-img-cropper.component";
import { RcLogoutComponent } from "./rc-logout/rc-logout.component";
import { RcBusyComponent } from "./rc-busy/rc-busy.component";
import { RcCalendarComponent } from "./rc-calendar/rc-calendar.component";
import { RcToggleButtonComponent } from "./rc-toggle-button/rc-toggle-button.component";
import { RcBreadcrumbsComponent } from "./rc-breadcrumbs/rc-breadcrumbs.component";
import { RcPageHeaderComponent } from "./rc-page-header/rc-page-header.component";
import { RcOptionDirective } from "./rc-option/rc-option.directive";
import { RcSafeHtmlPipe } from "./rc-safe-html/rc-safe-html.pipe";
import { RcCurrencyPipe } from "./rc-currency/rc-currency.pipe";
import { RcHammerGestureConfig } from "app/common/gesture/gesture-config";
import { RcRouteTransformerDirective } from "./rc-route-transformer/rc-route-transformer.directive";
import { environment } from "../../environments/environment";
import * as Sentry from "@sentry/browser";
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { RcNotOptionDirective } from "./rc-option/rc-not-option.directive";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgBusyModule.forRoot(
      new BusyConfig({
        template: RcBusyComponent,
        delay: 200,
        minDuration: 600,
      })
    ),
    FileUploadModule,
    NouisliderModule,
    ToastrModule.forRoot(toastrOptions()),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger",
    }),
    HammerModule,
    NgxSliderModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    RcErrorComponent,
    RcFooterComponent,
    RcSearchListComponent,
    RcLoaderComponent,
    RcNotAuthorizedComponent,
    RcFieldComponent,
    RcTextFieldComponent,
    RcTypeaheadFieldComponent,
    RcSelectFieldComponent,
    RcSwitchFieldComponent,
    RcDateFieldComponent,
    RcControlMessagesComponent,
    RcSortableComponent,
    RcResizingCroppingImagesComponent,
    RcLogoutComponent,
    RcBusyComponent,
    RcCalendarComponent,
    RcToggleButtonComponent,
    RcBreadcrumbsComponent,
    RcPageHeaderComponent,
    RcSortableDirective,
    RcOptionDirective,
    RcNotOptionDirective,
    RcSafeHtmlPipe,
    RcCurrencyPipe,
    RcRouteTransformerDirective
  ],
  providers: [
    RcControlMessagesService,
    RcSortableService,
    RcBusyGuardService,
    RcUnloadGuardService,
    RcSafeHtmlPipe,
    RcCurrencyPipe,
    {
      provide: ErrorHandler,
      useFactory: errorHandler,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: RcHammerGestureConfig,
    },
    provideEnvironmentNgxMask()
  ],
  exports: [
    NgbModule,
    NgBusyModule,
    NgxSliderModule,
    ConfirmationPopoverModule,
    RcErrorComponent,
    RcFooterComponent,
    RcSearchListComponent,
    RcLoaderComponent,
    RcNotAuthorizedComponent,
    RcTextFieldComponent,
    RcTypeaheadFieldComponent,
    RcSelectFieldComponent,
    RcSwitchFieldComponent,
    RcDateFieldComponent,
    RcControlMessagesComponent,
    RcSortableComponent,
    RcResizingCroppingImagesComponent,
    RcLogoutComponent,
    RcBusyComponent,
    RcCalendarComponent,
    RcToggleButtonComponent,
    RcBreadcrumbsComponent,
    RcPageHeaderComponent,
    RcSortableDirective,
    RcOptionDirective,
    RcNotOptionDirective,
    RcSafeHtmlPipe,
    RcCurrencyPipe,
    RcRouteTransformerDirective,
  ],
})
export class RcWidgetModule {}

export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Sentry.captureException(err);
  }
}

export function errorHandler() {
  if (environment.production) {
    return new SentryErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

export function toastrOptions() {
  return {
    animate: "fade",
    dismiss: "auto",
    showCloseButton: true,
    newestOnTop: true,
    enableHTML: true,
    positionClass: "toast-bottom-right",
  };
}
