export function model(): string {
  return "model";
}

export interface AppEnvironment {
  // pulled from NX for compatability
  production: boolean,
  // env: 'test' | 'demo' | 'prod',
  // api_root_url: string,
  // api_email_validator_url: string,
  // api_email_validator_timeout: number,
  // app_root_url: string,
  // sentry?: {
  //   dsn: string,
  //   traceSampleRate: number
  // }
}

export interface IAOK {
  id?: number;
  title: string;
  category?: string;
  group?: string;
  completed_date?: Date; // TODO Is this used?  if sowhat the heck for?
}

export interface IAOKCategory {
  id?: number;
  category: string;
  aoks: Array<IAOK>;
}

export interface IGroup {
  hash?: string;
  name?: string;
  group_name?: string;
  group_leader_name?: string;
}

export interface IFundraiserDetails {
  hash?: string;
  name?: string;
  name_prefix?: string;
  start_date?: string;
  end_date?: string;
  aok_categories?: IAOKCategory[];
  groups?: IGroup[];
  links?: { [id: string]: String };
  meta?: FundraiserMeta;
  options?: { [option: string]: any }
}

export interface CommunicationsPreview {
  slug: string;
  description: string;
  blurb: string;
  html: string;
}

// @see django/fundriaser/admin_actions.py#select_extra_options
export class FundraiserMetaExtraOptions {
  groupCategoryFieldLabel?: string;
  groupFieldLabel?: string;
  groupRegistrationLabel?: string;
  enableGroupCategory?: boolean = false;
  subgroupOptions?: string
  subgroupLabel?: string
}

export class FundraiserMeta {
  todo_list: Array<string>; // this holds the slugs for completed TODOs
  extra_options: FundraiserMetaExtraOptions;
  planStyle?: string;
  setupState?: any; // holds the setupState from setup.  might be blank for old fundraisers.
  incentives: FundraiserMetaIncentives;
  checklist_prepare?: any; // defined by the checklist/prepare compnent.
  checklist_handout?: any; // defined bu the checklist/handout component.

  // the last release notes the organizer viewed typically (yyymmdd)
  releaseNotes: number;
}

export class FundraiserOptions {
  group_labels: { [option: string]: string }
}
export class FundraiserMetaIncentives {
  reviewed?: boolean = false;
  plan?: string;
  prize?: boolean = false;
  ordered?: boolean = false;
}

export class RaiseCrazeAPIList<T> {
  count: number;
  next?: string;
  previous?: string;
  results: Array<T> = [];

  constructor(
    count?: number,
    next?: string,
    previous?: string,
    results?: Array<T>
  ) {
    this.count = count;
    this.next = next;
    this.previous = previous;
    this.results = results == null ? [] : results;
  }
}

export class CloudinaryMedia {
  bytes: number;
  created_at: string;
  etag: string;
  existing: boolean;
  format: string;
  height: number;
  original_filename: string;
  public_id: string;
  resource_type: string;
  secure_url: string;
  signature: string;
  tags: string[];
  type: string;
  url: string;
  version: number;
  width: number;
}
