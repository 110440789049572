import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { RcControlMessagesService } from "./rc-control-messages.service";

@Component({
  selector: "rc-control-messages",
  templateUrl: "./rc-control-messages.component.html",
  styleUrls: ["./rc-control-messages.component.css"],
})
export class RcControlMessagesComponent {
  @Input() control: UntypedFormControl;

  constructor() {}

  get error_message() {
    if (this.control == null) {
      return null;
    }

    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) {
        if (propertyName === "message") {
          return this.control.errors[propertyName];
        } else if (this.control.dirty || this.control.touched) {
          return RcControlMessagesService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          );
        }
      }
    }

    return null;
  }

  get is_valid() {
    if (this.control.valid && (this.control.dirty || this.control.touched)) {
      return true;
    }

    return false;
  }
}
