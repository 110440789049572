import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { CLIENT_TYPE_SCHOOL } from "@raisecraze/org-bl";
import * as moment from "moment";

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ZIPCODE_REGEX = /^\d{5}$/;
export const CURRENCY_REGEX = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;

@Injectable()
export class AppValidators {
  constructor() {}

  emailValidator(control) {
    return EMAIL_REGEX.test(control.value)
      ? null
      : {
          email: false,
        };
  }

  optionalEmailValidator(control) {
    if (control.value != null && control.value !== "") {
      return EMAIL_REGEX.test(control.value)
        ? null
        : {
            email: false,
          };
    }

    return null;
  }

  zipCodeValidator(control) {
    return ZIPCODE_REGEX.test(control.value)
      ? null
      : {
          zipCode: false,
        };
  }

  currencyValidator(control) {
    return CURRENCY_REGEX.test(control.value)
      ? null
      : {
          currency: false,
        };
  }

  futureDateValidator(control) {
    if (control.value != null) {
      const date = new Date(
        control.value["year"],
        control.value["month"] - 1,
        control.value["day"]
      );
      return moment().isBefore(moment(date))
        ? null
        : {
            futureDate: false,
          };
    }

    return null;
  }

  fundraiserDateValidator(startDate: string, endDate: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const start: UntypedFormControl = group.controls[startDate] as UntypedFormControl;
      const startVal = start.value;

      const end: UntypedFormControl = group.controls[endDate] as UntypedFormControl;
      const endVal = end.value;

      if (
        startVal != null &&
        startVal !== "" &&
        endVal != null &&
        endVal !== ""
      ) {
        // Make sure the start date comes before the end date
        if (moment(startVal).isAfter(moment(endVal))) {
          start.setErrors({ startDate: true });
        } else if (start.invalid) {
          // Wipe out any validation messages if they exist
          start.updateValueAndValidity();
        }
      }

      return null;
    };
  }

  // This validator conditionaly requires 'How will the acts of kindness be performed?' on the
  // organizer setup form. This validator should only run on the setup form.
  // @see https://support.dvt-inc.com/issues/6559
  fundraiserActsPerformedValidator(
    clientType: string,
    howActsPerformed: string
  ) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const clientTypeControl: UntypedFormControl = group.controls[
        clientType
      ] as UntypedFormControl;
      const howActsPerformedControl: UntypedFormControl = group.controls[
        howActsPerformed
      ] as UntypedFormControl;

      if (
        clientTypeControl.value != CLIENT_TYPE_SCHOOL &&
        !howActsPerformedControl.value
      ) {
        howActsPerformedControl.setErrors({ required: true });
      } else if (
        clientTypeControl.value == CLIENT_TYPE_SCHOOL &&
        howActsPerformedControl.invalid
      ) {
        howActsPerformedControl.setErrors({ required: false });
        howActsPerformedControl.reset();
      }

      return null;
    };
  }

  donorEmailValidator(nameKey: string, emailKey: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const name: UntypedFormControl = group.controls[nameKey] as UntypedFormControl;
      const nameVal: string = name.value;

      const email: UntypedFormControl = group.controls[emailKey] as UntypedFormControl;
      const emailVal: string = email.value;

      if (
        nameVal != null &&
        nameVal != "" &&
        (emailVal == null || emailVal == "")
      ) {
        email.setErrors({ required: true });
      } else if (
        (nameVal == null || nameVal == "") &&
        emailVal != null &&
        emailVal != ""
      ) {
        name.setErrors({ required: true });
      } else if (
        nameVal == null ||
        nameVal == "" ||
        emailVal == null ||
        emailVal == ""
      ) {
        if (name.invalid) {
          name.reset();
        }

        if (email.invalid) {
          email.reset();
        }
      }

      return null;
    };
  }

  // TODO: Re-work this so we can use dynamic controls/validators instead of hardcoding them
  requireIfNotDeleted(idKey: string, titleKey: string, validators = []) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const id: UntypedFormControl = group.controls[idKey] as UntypedFormControl;
      const idVal = id.value;

      const title: UntypedFormControl = group.controls[titleKey] as UntypedFormControl;
      const titleVal = title.value;

      if (idVal != null && idVal > 0 && (titleVal == null || titleVal === "")) {
        const _validators = [Validators.required, ...validators];
        title.setValidators(_validators); // Adds the 'required' validator
        title.updateValueAndValidity({ onlySelf: true });
      } else if (!title.pristine) {
        if (idVal > 0) {
          title.setValidators(validators); // Resets the validators
        } else {
          title.clearValidators(); // Clears the validators
        }

        title.updateValueAndValidity({ onlySelf: true });
      }

      return null;
    };
  }
}
