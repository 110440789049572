import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";

@Component({
  selector: "rc-field",
  template: "",
})
export class RcFieldComponent implements OnInit, AfterViewInit {
  @Input() type: string;
  @Input() control: UntypedFormControl;
  @Input() property: string;
  @Input() index: number = null;
  @Input() form: UntypedFormGroup;
  @Input() label: string;
  @Input() hint: string;
  @Input() focus: boolean = false;
  @Input() floatingLabel: boolean = true;
  @Input() floatingOffset: number = 15;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Output() changeCallback: EventEmitter<any> = new EventEmitter();
  @ViewChildren("textField") textField;

  focused = false;

  constructor() {}

  ngOnInit() {
    const component = this;
    if (component.changeCallback != null && component.control != null) {
      component.control.valueChanges.forEach((value: any) => {
        component.changeCallback.emit(value);
      });
    }
  }

  ngAfterViewInit() {
    const component = this;

    setTimeout(() => {
      if (component.focus) {
        component.textField.first.nativeElement.focus();
      }
    }, 1);
  }

  public getId() {
    if (this.property != null) {
      let id = this.property;

      if (this.index != null) {
        id = `${id}-${this.index}`;
      }

      return id;
    }

    return null;
  }

  public getType() {
    const component = this;
    if (component.type === undefined || component.type === "text") {
      return "text";
    }

    return component.type;
  }

  public onBlur() {
    this.focused = false;
  }

  public onFocus() {
    this.focused = true;
  }

  public getDivClass() {
    const component = this;
    let classes = component.getStatusClass();

    if (component.floatingLabel) {
      classes += " floating";
    }

    if (component.focused || component.hasValue()) {
      classes += " focused";
    }

    return classes;
  }

  public getStatusClass() {
    const component = this;
    let field = null;
    let errors = null;

    if (component.control != null) {
      field = component.control;
      errors = field.errors;
    } else if (component.property != null) {
      field = component.form.get(component.property);
      errors = field.errors;
    }

    if (errors && errors.hasOwnProperty("message")) {
      return "has-danger";
    }

    if (field != null) {
      if (field.valid && (field.dirty || field.touched)) {
        return "has-success";
      } else if (!field.valid && (field.dirty || field.touched)) {
        return "has-danger";
      }
    }

    return null;
  }

  public getLabelStyle() {
    const component = this;
    return component.focused || component.hasValue()
      ? null
      : {
          transform:
            "translate3d(" + component.floatingOffset + "px, 10px, 0) scale(1)",
        };
  }

  public hasValue() {
    const component = this;
    if (component.property == null) {
      return false;
    }

    const field = component.form.get(component.property);
    return (
      field !== null &&
      field.value !== null &&
      field.value !== "" &&
      field.value !== "0"
    );
  }
}
