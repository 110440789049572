import { Component, Input, OnInit } from "@angular/core";

/**
 * Shows a spinner when isBusy is true,  optionally a notBusy component can be provided for when it is not busy.
 *
 *  <rc-spinner isBusy="busy">
 *    <span #notBusy>
 *       <i class="fa fa-arrow-circle-right ml-s"></i>
 *     </span>
 *  </rc-spinner>
 */
@Component({
  selector: "rc-spinner",
  templateUrl: "./rc-spinner.component.html",
  styleUrls: ["./rc-spinner.component.scss"],
})
export class RcSpinnerComponent implements OnInit {
  @Input() isBusy: boolean;

  constructor() {}

  ngOnInit(): void {}
}
