import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";

/* Attempt at Guard that prevent changing tabs while something is going on..  not quite right
   as it prevented a route to redirect to th default tab
*/
@Injectable()
export class RcBusyGuardService  {
  busy: boolean;

  constructor(private store: Store<any>) {
    // this.store.select('detailForm').subscribe(state => {
    //   this.busy = state['fundraiser_update_status'] === 'Updating'
    // })
    // this.store.select('fundraiser').subscribe(state => {
    //   this.busy = state['fundraiser'] === null
    // })
  }

  canActivate() {
    return true; // !this.busy;
  }
}
