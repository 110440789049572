import { BreadcrumbService } from "./breadcrumb.service";
import { NgModule } from "@angular/core";
import {
  HttpClientModule,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { StoreModule, MetaReducer, ActionReducer, Action } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { localStorageSync } from "ngrx-store-localstorage";
import { storeFreeze } from "ngrx-store-freeze";
// import { storeLogger } from 'ngrx-store-logger'

import { WindowRef } from "./common/window/window";
import { AuthenticatedHttpInterceptor } from "./common/http/http.interceptor";
import { RcAppComponent, RcAppRootComponent } from "./app.component";
import { RcAppRoutingModule } from "./app.routing.module";
import { AppService } from "./app.service";
import { AppValidators } from "./app.validators";
import { DefaultAppState, reducers } from "./app.state";
import { RCConfigurationEffects } from "./app.effects";

import { RcNotFoundComponent } from "./rc-widgets/rc-notfound/rc-notfound.component";
import { RcWidgetModule } from "./rc-widgets/rc-widget.module";

import { RcNavigationComponent } from "./rc-modules/rc-navigation/rc-navigation.component";
import { RcAccountEffects } from "./rc-modules/rc-account/rc-account.effects";
import { RcAccountModule } from "./rc-modules/rc-account/rc-account.module";
import { RCConfigurationService } from "./config.service";

import { environment } from "environments/environment";

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];

// ---------------------------------------------------------------------------- Test Logging
const logger =
  (reducer: ActionReducer<any, any>) => (state: any, action: Action) => {
    console.log('Previous State', state);
    console.log('Action', action);

    const nextState = reducer(state, action);

    console.log('Next State', nextState);
    return nextState;
  };

if (!environment.production) {
  metaReducers.push(storeFreeze, logger);
}

@NgModule({
  declarations: [
    RcAppComponent,
    RcAppRootComponent,
    RcNotFoundComponent,
    RcNavigationComponent,
  ],
  imports: [
    StoreModule.forRoot(reducers, {
      initialState: DefaultAppState,
      metaReducers: metaReducers,
    }),
    EffectsModule.forRoot([RcAccountEffects, RCConfigurationEffects]),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: "csrftoken",
      headerName: "X-CSRFToken",
    }),
    RcWidgetModule,
    RcAppRoutingModule,
    RcAccountModule
  ],
  providers: [
    {provide: 'environment', useValue: environment},
    AppService,
    BreadcrumbService,
    RCConfigurationService,
    AppValidators,
    WindowRef,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticatedHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [RcAppRootComponent],
})
export class AppModule {}

// export function logger(reducer): any {
//   // default, no options
//   return storeLogger()(reducer)
// }

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      "auth",
      {
        app: ["sidebar"],
      },
    ],
    rehydrate: true,
  })(reducer);
}
