import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { AppValidators } from "app/app.validators";
import * as moment from "moment";

@Injectable()
export class RcAccountCreateParForm {
  constructor(private fb: UntypedFormBuilder, private appValidators: AppValidators) {}

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          this.appValidators.emailValidator.bind(this),
        ]),
      ],
      password: [null, Validators.compose([Validators.required])],
      birth_month: [null, Validators.compose([Validators.required])],
      birth_year: [null, Validators.compose([Validators.required])],
    });
  }

  clean(formGroup: UntypedFormGroup): any {
    const formModel: any = formGroup.value;
    const account: any = {
      email: formModel.email as string,
      password: formModel.password as string,
      birth_month: formModel.birth_month as number,
      birth_year: formModel.birth_year as number,
    };

    return account;
  }

  // This logic was copied from dj
  isValidAge(account: any, minAge: number = 13): boolean {
    const today: moment.Moment = moment();

    const birthMonth: number = account.birth_month as number;
    const birthYear: number = today.year() - (account.birth_year as number);
    const currentYear: number = today.year();
    const currentMonth: number = today.month() + 1;

    if (birthYear > currentYear - minAge) {
      return false;
    }

    if (birthYear < currentYear - minAge) {
      return true;
    }

    if (birthMonth < currentMonth) {
      return true;
    }

    return false;
  }
}
