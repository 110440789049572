<div class="form-group-inner" [formGroup]="form" [ngClass]="getDivClass()">
  <label
    for="{{ property }}"
    class="control-label"
    [ngStyle]="getLabelStyle()"
    *ngIf="floatingLabel"
    >{{ label }}</label
  >
  <label
    for="{{ property }}"
    class="control-label-static"
    *ngIf="!floatingLabel && label != null"
    >{{ label }}</label
  >
  <p class="form-text" [innerHtml]="hint" *ngIf="hint"></p>
  <div class="input-group d-flex align-items-stretch select-container">
    <ng-content select=".prefix"></ng-content>
    <select
      id="{{ property }}"
      formControlName="{{ property }}"
      class="form-control"
      (blur)="onBlur()"
      (focus)="onFocus()"
      *ngIf="property"
    >
      <option *ngIf="showBlankOption" [value]=""></option>
      <option *ngFor="let option of optionMap" [value]="option.key">
        {{ option.value }}
      </option>
    </select>
    <select
      id="{{ property }}"
      [formControl]="control"
      class="form-control"
      (blur)="onBlur()"
      (focus)="onFocus()"
      *ngIf="control"
    >
      <option *ngIf="showBlankOption" [value]=""></option>
      <option *ngFor="let option of optionMap" [value]="option.key">
        {{ option.value }}
      </option>
    </select>
    <ng-content select=".suffix"></ng-content>
  </div>
  <rc-control-messages
    [control]="form.controls[property]"
    class="form-control-feedback"
    *ngIf="property"
  ></rc-control-messages>
  <rc-control-messages
    [control]="control"
    class="form-control-feedback"
    *ngIf="control"
  ></rc-control-messages>
  <ng-content select=".message.form-control-feedback"></ng-content>
  <ng-content select=".form-text.text-muted"></ng-content>
</div>
