import {
  Component,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { RcFieldComponent } from "../rc-field/rc-field.component";

@Component({
  selector: "rc-select-field",
  templateUrl: "./rc-select-field.component.html",
  styleUrls: ["./rc-select-field.component.scss"],
})
export class RcSelectFieldComponent extends RcFieldComponent {
  @Input() options: any;
  @Input() floatingLabel: boolean = false;
  @Input() showBlankOption: boolean = false;

  // @Output() change: EventEmitter<any> = new EventEmitter()

  public optionMap: {};

  ngOnInit() {
    if (this.options) {
      this.setOptions(this.options);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes != null) {
      if ("options" in changes) {
        let _options = changes["options"];
        if (_options.currentValue != null) {
          this.setOptions(_options.currentValue);
        }
      }
    }
  }

  setOptions(options: any) {
    if (this.options instanceof Map) {
      const options: Map<any, string> = this.options
      this.optionMap = Array.from( options ).map(([key, value]) => ({
        key: key,
        value: value
      }))
    } else {
      this.optionMap = Object.keys(options).map((key) => ({
        key: key,
        value: options[key],
      }));
    }
  }
}
