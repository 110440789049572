import { Injectable } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { ApplicationActions } from "./app.actions";

@Injectable()
export class AppService {
  constructor(private store: Store<any>) {}

  isValid(form: any, controlList?: any) {
    let valid: boolean = true;

    if (form == null) {
      return false;
    }

    if (form instanceof UntypedFormArray) {
      for (let _form of form.controls) {
        for (let control of controlList) {
          if (!_form.get(control).valid) {
            valid = false;
            break;
          }
        }
      }
    } else {
      for (let control of controlList) {
        if (!form.get(control).valid) {
          valid = false;
          break;
        }
      }
    }

    return valid;
  }

  markControlsTouched(controls: Array<AbstractControl>): number {
    let errorCount = 0;
    for (let control of controls) {
      if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
        errorCount += control.errors ? Object.keys(control.errors).length : 0;
        const children = new Array<AbstractControl>();
        Object.keys(control.controls).map((controlName) => {
          children.push(control.get(controlName));
        });

        errorCount += this.markControlsTouched(children);
      } else {
        control.markAsTouched({ onlySelf: false });
        if (!control.valid) {
          errorCount++;
        }
      }
    }

    return errorCount;
  }

  validateForm(form: any, controlList?: any) {
    let component = this;
    let controls: Array<AbstractControl> = new Array();
    let forms = [];

    if (form.constructor.name == "FormArray") {
      for (let _form of form.controls) {
        forms.push(_form);
      }
    } else {
      forms.push(form);
    }

    for (let i = 0; i < forms.length; i++) {
      let _form = forms[i];
      if (
        controlList == null ||
        (controlList && Object.keys(controlList).length === 0)
      ) {
        Object.keys(_form.controls).map((controlName) => {
          controls.push(_form.get(controlName));
        });
      } else {
        for (let control of controlList) {
          Object.keys(_form.controls).map((controlName) => {
            if (control == controlName) {
              controls.push(_form.get(controlName));
            }
          });
        }
      }
    }

    // Triggers the ng validators on the form fields
    const errorCount = this.markControlsTouched(controls);

    // show the number of errors found
    let message = null;

    if (errorCount == 1) {
      message = `There was 1 error detected`;
    } else {
      message = `There were ${errorCount} errors detected`;
    }

    if (message != null) {
      // Dispatch a toast message
      this.store.dispatch(ApplicationActions.showErrorMessage(message));
    }

    // Scrolls the first field with an error into view
    setTimeout(() => {
      var errorDivs = document.getElementsByClassName("has-danger");
      if (errorDivs.length > 0) {
        errorDivs[0].scrollIntoView();
      }
    }, 50);
  }

  /**
   * Shows API errors on the form fields.
   *
   * [
   *   'email': ['Duplicate Email',],
   *   'name':  ['Required']
   * ]
   *
   * @param form
   * @param errors Array of error dictionaries [{field: [error..]},..]
   */
  displayAPIErrors(form: UntypedFormGroup, errors: any) {
    errors?.forEach((errorDict: {}) => {
      Object.keys(errorDict).forEach((key) => {
        if (form.contains(key)) {
          const control = form.get(key);
          control.setErrors({ message: errorDict[key] });
        }
      });
    });
  }

  displayFormErrors(form: UntypedFormGroup, errors: any) {
    const errorDict = errors.data;
    for (const key in errorDict) {
      if (key === "client") {
        // ugly way to handle client level errors...
        // before we fix this we might should change the api
        for (const clientKey in errorDict[key]) {
          if (form.contains(clientKey)) {
            const control = form.get(clientKey);
            control.setErrors({ message: errorDict[key][clientKey] });
          }
        }
      } else {
        if (form.contains(key)) {
          const control = form.get(key);
          control.setErrors({ message: errorDict[key] });
        }
      }
    }
  }

  markPristine(form: any) {
    if (form != null) {
      if (form instanceof UntypedFormArray) {
        for (let _form of form.controls) {
          _form.markAsPristine();
        }
      } else {
        form.markAsPristine();
      }
    }
  }

  isPristine(form: any) {
    if (form == null) {
      return false;
    }

    if (form instanceof UntypedFormArray) {
      for (let _form of form.controls) {
        if (!_form.pristine) {
          return false;
        }
      }
    } else {
      if (!form.pristine) {
        return false;
      }
    }

    return true;
  }
}
