<div class="wrapper-fluid" (keyup.enter)="tapCreate($event)">
  <h2 class="mb-3" [innerHtml]="'Welcome to Raise Craze!'"></h2>

  <div class="row no-gutters">
    <div class="col-sm-10 offset-sm-1">
      <h3 class="text-center ms-3 me-3">
        <span
          [innerHtml]="
            'In order to register for a Raise Craze account, you must be at least 13 years or the parent/legal guardian of a participant under the age of 13. '
          "
        ></span>
        <a
          href="https://www.ftc.gov/tips-advice/business-center/privacy-and-security/children%27s-privacy"
          target="_blank"
          [innerHtml]="'More&nbsp;Info'"
        ></a>
      </h3>
    </div>
  </div>

  <div class="row no-gutters" *ngIf="showValidationError">
    <div class="col-sm-10 offset-sm-1">
      <div
        class="alert alert-danger text-center ms-3 me-3"
        [innerHtml]="getAgeMessage()"
      ></div>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-sm-10 offset-sm-1">
      <form novalidate [formGroup]="form">
        <div class="form-group row">
          <rc-text-field
            class="col-12 mb-0"
            type="email"
            [form]="form"
            property="email"
            label="Email"
            maxlength="254"
          ></rc-text-field>
        </div>
        <div class="form-group row">
          <rc-text-field
            class="col-12"
            type="password"
            [form]="form"
            property="password"
            label="Password"
            maxlength="254"
          ></rc-text-field>
        </div>
        <div class="form-group row">
          <rc-select-field
            class="col-12 col-md-6"
            [form]="form"
            property="birth_month"
            label="Birth Month"
            [options]="monthList"
            [showBlankOption]="true"
            [floatingLabel]="true"
          ></rc-select-field>
          <rc-select-field
            class="col-12 col-md-6"
            [form]="form"
            property="birth_year"
            label="Birth Year"
            [options]="yearList"
            [floatingLabel]="true"
          ></rc-select-field>
        </div>
      </form>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-sm-10 offset-sm-1">
      <p class="ms-3 me-3">
        <span
          [innerHtml]="
            'By creating an account, you certify that you are over the age of 13 or the parent/legal guardian of a participant under the age of 13 and that you agree to Raise Craze\'s '
          "
        ></span>
        <a
          href="https://raisecraze.com/terms-conditions"
          target="_blank"
          [innerHtml]="'Terms&nbsp;of&nbsp;Service'"
        ></a>
        <span [innerHtml]="' and '"></span>
        <a
          href="https://raisecraze.com/privacy-policy"
          target="_blank"
          [innerHtml]="'Privacy&nbsp;Policy'"
        ></a>
      </p>

      <div class="form-group clearfix buttons mb-0">
        <a
          href="#"
          class="login float-start d-none d-md-block"
          [ngClass]="{ disabled: busy }"
          (click)="tapLogin($event)"
        >
          <strong [innerHtml]="'Already have an account?'"></strong>
        </a>
        <button
          id="id_create"
          class="btn btn-primary purple float-end"
          type="button"
          (click)="tapCreate($event)"
          [disabled]="busy"
        >
          <span [innerHtml]="'Create Account'" *ngIf="!busy"></span>
          <rc-spinner [isBusy]="busy"></rc-spinner>
        </button>
        <div class="d-block d-md-none mt-2 text-center">
          <a
            href="#"
            class="login"
            [ngClass]="{ disabled: busy }"
            (click)="tapLogin($event)"
          >
            <strong [innerHtml]="'Already have an account?'"></strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
