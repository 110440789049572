import { Injectable } from "@angular/core";

@Injectable()
export class RcControlMessagesService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: "This field is required.",
      usernameNotAvailable: "This username has already been registered.",
      usernameError:
        "We're having trouble validating this username. Please try again later.",
      passwordInvalid:
        "Invalid password. Password must be at least 8 characters long, and contain a number.",
      passwordMatch: "The passwords do not match",
      email: "Enter a valid email address.",
      emailNotAvailable: "This email address has already been registered.",
      emailError:
        "We're having trouble validating this email address. Please try again later.",
      zipCode: "This zip code is invalid.",
      currency: "Enter a valid dollar amount.",
      maxlength: `Maximum of ${validatorValue.requiredLength} characters allowed.`,
      minlength: `Minimum of ${validatorValue.requiredLength} characters required.`,
      ngbDate: "This date is invalid.",
      futureDate: "Date must be set in the future",
      startDate: "The start date must start before the end date",
    };

    return config[validatorName];
  }

  constructor() {}
}
