// tslint:disable:no-access-missing-member
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RcFieldComponent } from "../rc-field/rc-field.component";
import { RcCurrencyPipe } from "../rc-currency/rc-currency.pipe";
import { SubscriptionManager } from "../../common/subscriptions/subscription.manager";

@Component({
  selector: "rc-text-field",
  templateUrl: "./rc-text-field.component.html",
  styleUrls: ["./rc-text-field.component.scss"],
})
export class RcTextFieldComponent
  extends RcFieldComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() mask: string | null = null;
  @Input() maxlength: number;
  @Input() maxlengthShowPercent = 0.8;
  @Input() counterEnabled: boolean = true;
  @Input() autoTab: string = null;
  @Input() inputType = "text";
  @Output() onBlurCallback: EventEmitter<any> = new EventEmitter();

  canShowCounter: boolean = false;
  valueLengthBehavior = new BehaviorSubject(0);
  valueLength: number;
  _subs: SubscriptionManager = new SubscriptionManager();

  inputMask: string = null;

  constructor(protected currencyPipe: RcCurrencyPipe) {
    super();
  }

  ngOnInit(): any {
    if (this.maxlengthShowPercent === -1) {
      this.counterEnabled = false;
    } else if (this.counterEnabled && this.maxlengthShowPercent > 0) {
      this._subs.push(
        this.valueLengthBehavior.subscribe((valueLength: number) => {
          this.valueLength = valueLength;
          this.canShowCounter =
            this.maxlengthShowPercent <= this.valueLength / this.maxlength;
        })
      );
    }

    let control = null;

    if (this.property !== undefined) {
      control = this.form.get(this.property);
    } else {
      control = this.control;
    }

    if (control) {
      this.valueLengthBehavior.next(control.value ? control.value.length : 0);
      this._subs.push(
        control.valueChanges.subscribe((v: any) =>
          this.valueLengthBehavior.next(v ? v.length : 0)
        )
      );
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const change = changes[propName];
        switch (propName) {
          case "type":
          case "mask": {
            this.inputType =
              this.type === "phone"
                ? "tel"
                : this.type === "currency"
                ? "text"
                : super.getType();
            this.inputMask =
              this.mask != null
                ? this.mask
                : this.type === "phone"
                ? "(000) 000-0000"
                : null;
          }
        }
      }
    }
  }

  public onInputEntry($event) {
    if ($event.target.value.length >= this.maxlength && this.autoTab != null) {
      document.getElementById(this.autoTab).focus();
    }
  }

  public onBlur() {
    super.onBlur();

    if (this.form != null && this.type === "currency") {
      const field = this.form.get(this.property);
      field.setValue(this.currencyPipe.transform(field.value));
    }

    this.onBlurCallback.emit();
  }

  public onFocus() {
    super.onFocus();

    if (this.form != null && this.type === "currency") {
      const field = this.form.get(this.property);
      field.setValue(this.currencyPipe.parse(field.value));
    }
  }

  getMaxLength() {
    return this.maxlength;
  }

  getMaxLengthText() {
    return `${this.valueLength}/${this.maxlength}`;
  }

  doShowCharCounter() {
    return this.maxlength != null && this.counterEnabled && this.canShowCounter;
  }
}
