import { Action } from "./upgrade";
import {
  NOT_AUTHORIZED,
  USER_LOGGED_IN,
  SET_ERROR,
  ApplicationActions,
  AppMessage,
  configSuccess,
} from "./app.actions";
import { Sidebar } from "@raisecraze/org-bl";
import { createReducer, on } from "@ngrx/store";

const initialRCConfigurationState = {};

export const rcConfigurationReducer = createReducer(
  initialRCConfigurationState,
  on(configSuccess, (state, { rcConfiguration }) => rcConfiguration)
);

export class ApplicationState {
  user: String;
  status: String;
  title: string[];
  sidebar: Sidebar;
  route_loading: string;
  error: any;
  message: AppMessage;
}

export const DefaultApplicationState: ApplicationState = {
  user: null,
  status: null,
  title: ["Raise Craze"],
  sidebar: new Sidebar(),
  route_loading: null,
  error: null,
  message: null,
};

export function ApplicationReducer(
  state: ApplicationState = DefaultApplicationState,
  action: Action
) {
  switch (action.type) {
    case ApplicationActions.MESSAGE:
      return Object.assign({}, state, {
        message: action.payload,
      });

    case ApplicationActions.TITLE:
      return Object.assign({}, state, {
        title: action.payload,
      });

    case ApplicationActions.SIDEBAR_TOGGLE:
      var sidebar: Sidebar = state.sidebar;
      sidebar = Object.assign({}, sidebar, {
        opened:
          action.payload === undefined ? !state.sidebar.opened : action.payload,
      });

      return Object.assign({}, state, {
        sidebar: sidebar,
      });

    case ApplicationActions.SIDEBAR_ACTIVE_GROUP:
      var sidebar: Sidebar = state.sidebar;
      sidebar = Object.assign({}, sidebar, {
        activeGroup: action.payload === undefined ? null : action.payload,
      });

      return Object.assign({}, state, {
        sidebar: sidebar,
      });

    case ApplicationActions.ROUTE_LOADING:
      return Object.assign({}, state, {
        route_loading: action.payload ? action.payload : null,
      });

    case NOT_AUTHORIZED:
      return Object.assign({}, state, {
        status: NOT_AUTHORIZED,
      });

    case USER_LOGGED_IN:
      return Object.assign({}, state, {
        user: action.payload,
        status: null,
      });

    case SET_ERROR:
      return Object.assign({}, state, {
        error: action.payload,
      });

    default:
      return state;
  }
}

export class CloudinaryState {
  // This is an internal category name that the cloudinary component uses
  // to differenciate multiple cloudinary components being used in the same template.
  mediaType: string;

  // The folder name that the image is saved to in cloduinary
  folderName: string;

  // The shape of the cropper. Could be square or rounded
  shape: string;

  // Instructs the cropper to zoom out until the entire image fits into the cropping area
  extraZoomOut: boolean;

  // The text of the 'Browse Files' button
  browseText: string;

  // This is the base64 encode version of the selected image
  imgSrc: string;

  // This is the file object of the selected image
  file: File;

  // This the the cloudinary response data
  data: any;

  // The max-width of the image cropper
  maxWidth: number;

  // The max-height of the image cropper
  maxHeight: number;

  // The final width of the saved image
  saveWidth: number;

  // The final height of the saved image
  saveHeight: number;

  // This scales the image down on mobile screens so it will fit the window (< 576px).
  // The default is 1.5 which will scale the cropper to 2/3 of its original size.
  mobileScale: number;

  // The route that the page redirects to when the modal is closed
  redirectPath: string;

  // type of image, set to pdf for logos (organizer)
  imageType: string;
}

export const OrgCloudinaryState: CloudinaryState = {
  mediaType: null,
  folderName: null,
  imgSrc: null,
  file: null,
  data: null,
  browseText: "Logo",
  maxWidth: 400,
  maxHeight: 400,
  saveWidth: 800,
  saveHeight: 800,
  mobileScale: 1.5,
  shape: "square",
  extraZoomOut: true,
  redirectPath: "org/edit",
  imageType: "image/png",
};

export const ParCloudinaryState: CloudinaryState = {
  mediaType: null,
  folderName: null,
  imgSrc: null,
  file: null,
  data: null,
  browseText: "Picture",
  maxWidth: 400,
  maxHeight: 400,
  saveWidth: 800,
  saveHeight: 800,
  mobileScale: 1.5,
  shape: "rounded",
  extraZoomOut: false,
  redirectPath: "par/edit",
  imageType: "image/jpeg",
};

export function CloudinaryReducer(state: any, action: Action) {
  switch (action.type) {
    // sets the cloudinary to the correct settings for organizer
    case ApplicationActions.RESET_CLOUDINARY_ORG:
      return Object.assign({}, state, OrgCloudinaryState);

    // sets the cloudinary to the correct settings for participant
    case ApplicationActions.RESET_CLOUDINARY_PAR:
      return Object.assign({}, state, ParCloudinaryState);

    case ApplicationActions.SET_CLOUDINARY:
      return Object.assign({}, state, {
        mediaType: action.payload.mediaType,
        folderName: action.payload.folderName,
        browseText: action.payload.browseText || state.browseText,
        shape: action.payload.shape,
        extraZoomOut: action.payload.extraZoomOut,
        redirectPath: action.payload.redirectPath,
      });

    case ApplicationActions.UPDATE_CLOUDINARY:
      return Object.assign({}, state, action.payload);

    case ApplicationActions.CLEAR_CLOUDINARY_DATA:
      return Object.assign({}, state, {
        mediaType: null,
        folderName: null,
        data: null,
      });

    case ApplicationActions.RESET_CLOUDINARY:
      return Object.assign({}, state, {
        mediaType: null,
        folderName: null,
        imgSrc: null,
        file: null,
        data: null,
      });

    default:
      return state;
  }
}
