import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

import { selectAuth } from "app/app.state";
import { IAppState } from "app/app.state";
import { RcAccountActions } from "app/rc-modules/rc-account/rc-account.actions";
import { BreadcrumbService } from "app/breadcrumb.service";

/**
 * This is an HTTP interceptor that allows us to catch specific error responses from the server.
 *
 * Usage:
 *
 *   @NgModule({
 *     providers: [{
 *       provide: HTTP_INTERCEPTORS,
 *       useClass: AuthenticatedHttpInterceptor,
 *       multi: true
 *     }]
 *   })
 */
@Injectable()
export class AuthenticatedHttpInterceptor implements HttpInterceptor {
  auth$: Observable<string> = this.store.select(selectAuth);

  private auth: string;

  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private accountActions: RcAccountActions,
    private breadcrumbs: BreadcrumbService
  ) {
    this.auth$.subscribe((auth) => (this.auth = auth ));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth != null) {
      req = req.clone({
        setHeaders: {
          Authorization: `Token ${this.auth}`,
        },
      });
    }

    return next
      .handle(req)
      .pipe(catchError((err) => this.catchErrors(err, [401, 403])));
  }

  catchErrors(err: HttpErrorResponse, errorCodes: number[]): Observable<any> {
    if (err && errorCodes.indexOf(err.status) > -1) {
      this.breadcrumbs.add("auth", `Logged out via catchErrors: ${err.status}`);
      this.store.dispatch(this.accountActions.logout());
      this.router.navigate(["/"]);
    }

    return throwError(err);
  }
}
