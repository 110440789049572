
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

/**
 * @deprecated,  move to haschanges..
 */
export interface RcComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean> | any;
}

export interface RcComponentHasChanges {
  hasChanges: () => boolean;
}

@Injectable()
export class RcUnloadGuardService
  
{
  canDeactivate(
    component: RcComponentCanDeactivate | RcComponentHasChanges
  ): boolean | Observable<boolean> | any {
    if (!component) {
      return true;
    }
    if ("hasChanges" in component) {
      if (!component.hasChanges()) {
        return true;
      }
    } else if ("canDeactivate" in component) {
      if (component.canDeactivate()) {
        return true;
      }
    }

    return confirm(
      "WARNING: You have unsaved changes. Press Cancel to go back and save " +
        "these changes, or OK to lose these changes."
    );
  }
}
