import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RCConfiguration } from "./app.state";
import { Observable } from "rxjs";

@Injectable()
export class RCConfigurationService {
  private configUrl = "/api-v1/config/";

  constructor(private http: HttpClient) {}

  loadConfig(): Observable<RCConfiguration> {
    return this.http.get(this.configUrl, {});
  }
}
