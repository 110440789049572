import {
  FundraiserMeta,
  IAOK,
  IAOKCategory,
  IFundraiserDetails,
  IGroup,
  CloudinaryMedia,
  FundraiserOptions,
} from "@raisecraze/model";
import { Account } from "@raisecraze/model";

export enum FUNDRAISER_STATUS {
  DEMO = "DEMO",
  LIVE = "LIVE",
  CLOSED = "CLOSED",
}

export enum PAYMENT_PROVIDER {
  STRIPE = "give_stripe"
}

export enum FR_OPTIONS {
  ENABLE_GROUP_SIZE = 'orgdash-enableGroupSize',
  ENABLE_GROUP_AOK = 'orgdash-enableGroupAOK',
  ENABLE_GROUP_DASHBOARD = 'orgdash-requestGroupLeaderDashboards',
  HIDE_READINESSCHECK = 'orgdash-hideReadinessCheck',
  HANDOUT_LANGUAGES = 'orgdash-handout-languages',
  ENABLE_AVERAGES = 'summary-leaderboardColumns' // reused existing option value 25-02-19
}

export enum FR_DISPOSITION {
  COMPLIANCE = 'compliance'
}

export interface IPaymentProcessorMeta {
  // ...
}

export interface IPaymentProcessor {
  is_active: boolean;
  provider: PAYMENT_PROVIDER;
  meta: IPaymentProcessorMeta;
}

export class Message {
  type: number;
  message?: string;

  constructor(type?: number, message?: string) {
    this.type = type;
    this.message = message;
  }
}

export class Sidebar {
  opened: boolean = true;
  activeGroup: string;
}

// -------------------------------------------------------------- Start of Organizer
// These constants match demo and production - 2020-07-23
export const CLIENT_TYPE_SCHOOL = 1;
export const CLIENT_TYPE_SPORTS_TEAM = 2;
export const CLIENT_TYPE_OTHER = 6;
export const CLIENT_TYPE_CLUB = 7;
export const CLIENT_TYPE_BAND = 8;
export const CLIENT_TYPE_CLASS_GRADE = 9;
export const CLIENT_TYPE_TROOP = 10;
export const CLIENT_TYPE_YOUTH_GROUP = 11;

export class Client {
  name: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  phone_number: string;
  check_payable_to: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  client_type?: number;
  client_type_display?: string;
  structure_type?: number;
  tax_charity_ein?: string;
}

export interface IFundraiserIncentivePlan {
  id: string;
  title: string;
  incentives: IFundraiserIncentive[];
}
export interface IFundraiserIncentive {
  id: string;
  title: string;
  hidden: boolean;
}

export class Fundraiser {
  details: FundraiserDetails;
  payment?: IPaymentProcessor;
  incentivePlans?: IFundraiserIncentivePlan[];
}

// Image model
export interface SocialMediaImage {
  /**
   * The type of social media platform, e.g., 'facebook' or 'instagram'.
   */
  type: string;

  /**
   * The URL of the image.
   */
  url: string;
}

// SocialMediaPost model
export interface SocialMediaPost {
  /**
   * The unique slug of the post.
   */
  slug: string;

  /**
   * The date of the post.
   */
  date: Date;

  /**
   * The title of the post.
   */
  title: string;

  /**
   * The body text of the post.
   */
  body: string;

  /**
   * A list of images associated with the post.
   */
  images: SocialMediaImage[];
}

export class FundraiserDetails implements IFundraiserDetails {
  hash?: string;
  fr_token?: { [id: string]: string }; // signed token that represents the fr PK (report, doc)
  name?: string;
  organization_name?: string;
  name_prefix?: string;
  start_date?: string;
  end_date?: string;
  first_registration_date?: string;
  client?: Client;
  cause?: string;
  goal?: string;
  age_type?: string;
  aok_categories?: AOKCategory[];
  groups?: Group[];
  date_setup_completed?: Date;
  links?: { [id: string]: string } = {};
  media?: Media[] = [];
  library?: { [id: string]: LibraryDocument[] };
  image?: CloudinaryMedia;
  meta?: FundraiserMeta;
  status?: FUNDRAISER_STATUS;
  disposition?: string;
  leaderboard?: Leaderboard[];
  timezone?: string;
  options?: FundraiserOptions | { [option: string]: any };
  social_media_posts?: SocialMediaPost[]
  compliance?: { [option: string]: any };

}

export function goalAsNumber(fundraiserDetails: FundraiserDetails): number {
  return +fundraiserDetails?.goal?.replace(/,/g, "");
}
export class Media {
  id: any;
  absolute_url: string;
  meta: string;
  deleting?: boolean;
}

export class LibraryDocument {
  title: String;
  description: String;
  link: String;
}

export class AOKType {
  id: number;
  text: string;

  constructor(id?: number, text?: string) {
    this.id = id;
    this.text = text;
  }
}

export class AOKCategory implements IAOKCategory {
  id?: number;
  category: string;
  aoks: Array<AOK>;

  constructor(id?: number, category?: string, aoks?: Array<AOK>) {
    this.id = id;
    this.category = category;
    this.aoks = aoks;
  }
}

/**
 * Fundraiser level recommended AOK
 */
export class AOK implements IAOK {
  id?: number;
  title: string;
  category?: string;
  group?: string;
  completed_date?: Date; // TODO Is this used?  if sowhat the heck for?

  constructor(id?: number, title?: string, category?: string, group?: string) {
    this.id = id;
    this.title = title;
    this.category = category;
    this.group = group;
  }
}

export class Group implements IGroup {
  id?: number;
  uuid?: string;
  hash?: string;
  name?: string; // the api generates a name,  from before name and leader were 2 fields.
  group_name?: string;
  group_leader_name?: string;
  group_leader_email?: string;
  group_leader_registered?: boolean; // read only,  has this leader already registered?
  group_size?: number; // optional size of the group.
  acts_of_kindness?: number; // optional group aoks
  participant_count?: number; // read only,  number of participants registered
  send?: boolean; // write only, should send this Group leader an invitation email.
}

export class Donation {
  id: number;
  student: string;
  student_email: string;
  name: string;
  email: string;
  date_paid: Date;
  amount: number;
  update_url: string;
  type: string;
}

export class Leaderboard {
  group_id?: number;
  group_name?: string;
  group_acts_of_kindness?: number;
  amount?: number;
  donation_count?: number;
  aok_count?: number;
  aok_completed_count?: number;
  registered_count?: number;
  email_count?: number;
}

// -------------------------------------------------------------------------- newFundraiser
export function newFundraiser(accont: Account): Fundraiser {
  // return a new blank fundraiser
  const f = new Fundraiser();
  f.details = new FundraiserDetails();
  f.details.client = new Client();
  f.details.client.client_type = null;
  f.details.age_type = null;
  f.details.groups = [];
  f.details.aok_categories = [];
  return f;
}

// --- old org.model
export class Participant {
  id?: number;
  uuid?: string;
  first_name?: "";
  last_name?: "";
  full_name?: "";
  aok?: ParticipantAOKCount;
  email: String;
  picture: String;
  date_setup_completed?: Date;
  pledger_count: 0;
  donation_count: 0;
  donation_amount: 0;
  donation_url: String = "";
  group?: {
    uuid: string;
    name: string;
  };
  metadata: {
    subgroup: string;
  };
}

export class ParticipantAOKCount {
  completed: number;
  total: number;
}

export class Payment {
  id: number;
  uuid: string;
  student: string;
  name: string;
  email?: string;
  date_paid: Date;
  amount: number;
  update_url?: string;
  type: string;
  group?: {
    id: number;
    name: string;
  };
  meta?: any;
  memo?: string; // not used,  maybe remove meta and update api to use memo.
}

export class CashCheck {
  id?: number;
  group: number;
  student: number;
  name: string;
  email?: string;
  payment_type: string = "check";
  amount: number;
  meta?: any;
  memo?: string;
}

export class FundraiserStats {
  completed_aok?: number;
  donations?: number;
  total_raised?: number;
  net_raised?: number;
  participants?: number;
  emails_sent?: number;
  total_aok?: number;
  cash_check_raised?: number;
  online_raised?: number;
}

export class FundraiserResults {
  stats: FundraiserStats;
  dollars_raised?: [];
  aoks_completed?: [];
  aoks_selected?: [];
  emails_sent?: [];
  participants?: [];
}
