import { Action } from "../../upgrade";
import { Account } from "@raisecraze/model";
import { RcAccountActions } from "./rc-account.actions";

export function RcAuthReducer(state: { token: string } = null, action: Action | any) {
  switch (action.type) {
    case RcAccountActions.ACCOUNT_IMPERSONATE:
      return { token : action.payload };

    case RcAccountActions.ACCOUNT_LOGIN_SUCCESS:
      return  { token : action.payload.token };

    case RcAccountActions.createParAccountSuccess.type:
    case RcAccountActions.createOrgAccountSuccess.type:
      return  { token : action.account.token };

    case RcAccountActions.ACCOUNT_LOGOUT:
      localStorage.clear();
      return null;

    default:
      return state;
  }
}

// This needs to be convered to createReducer,  for now as the
// actions convert we can use .type (@see RcAccountActions.changeEmailSuccess.type)
// this is the reason for the |any on the action type.
// once these are all .type we can move to the new createReducer style
export function RcAccountReducer(state: Account = null, action: Action | any) {
  switch (action.type) {
    case RcAccountActions.SET_ACTING_AS:
      return Object.assign({}, state, {
        acting: action.payload,
      });

    case RcAccountActions.ACCOUNT_IMPERSONATE:
    case RcAccountActions.ACCOUNT_LOGOUT:
    case RcAccountActions.createParAccountSuccess.type:
    case RcAccountActions.createParAccountSuccess.type:
    case RcAccountActions.refreshAccount.type:
      return null;

    case RcAccountActions.ACCOUNT_GET_SUCCESS:
    case RcAccountActions.ACCOUNT_LOGIN_SUCCESS:
      return Object.assign(new Account(), state, action.payload);

    case RcAccountActions.changeEmailSuccess.type:
      return Object.assign(new Account(), state, action.account);

    default:
      return state;
  }
}
