<div class="page cardview d-flex flex-column h-100">
  <div class="logo">
    <img src="/static/gen1/img/logo.large.png" alt="Raise Craze" />
  </div>

  <div class="inner mb-auto">
    <rc-account-login
      (actionType)="handleActionType($event)"
      *ngIf="showLogin"
    ></rc-account-login>
    <rc-account-create
      accountType="par"
      (actionType)="handleActionType($event)"
      *ngIf="showCreate"
    ></rc-account-create>
  </div>

  <rc-footer></rc-footer>
</div>
