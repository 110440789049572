import { Injectable } from "@angular/core";
import { MessageType } from "./app.enums";
import { createAction, props } from "@ngrx/store";
import { RCConfiguration } from "./app.state";

export const NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const USER_LOGGED_IN = "USER_LOGGED_IN";

export const PARTICIPANT_LOAD_START = "PARTICIPANT_LOAD_START";
export const PARTICIPANT_LOAD_END = "PARTICIPANT_LOAD_END";
export const PARTICIPANT_LOAD_ERROR = "PARTICIPANT_LOAD_ERROR";
export const PARTICIPANT_RESET = "PARTICIPANT_RESET"

export const SETUP_PAGE = "SETUP_PAGE";
export const SETUP_UPLOAD_LOGO = "SETUP_UPLOAD_LOGO";
export const SETUP_REVIEW_SUGGESTED_AOKS = "SETUP_REVIEW_SUGGESTED_AOKS";

export const SET_ERROR = "SET_ERROR";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";
export const FATAL_ERROR = "FATAL_ERROR";

export class AppMessage {
  constructor(public type: MessageType, public message: string) {}
}

@Injectable()
export class ApplicationActions {
  static MESSAGE = "[Application] MESSAGE";
  static TITLE = "[Application] TITLE";
  static SIDEBAR_TOGGLE = "[Application] SIDEBAR_TOGGLE";
  static SIDEBAR_ACTIVE_GROUP = "[Application] SIDEBAR_ACTIVE_GROUP";
  static ROUTE_LOADING = "[Application] ROUTE_LOADING";
  static SET_CLOUDINARY = "[Application] SET_CLOUDINARY";
  static UPDATE_CLOUDINARY = "[Application] UPDATE_CLOUDINARY";
  static CLEAR_CLOUDINARY_DATA = "[Application] CLEAR_CLOUDINARY_DATA";
  static RESET_CLOUDINARY = "[Application] RESET_CLOUDINARY";
  static RESET_CLOUDINARY_ORG = "[Application] RESET_CLOUDINARY ORG";
  static RESET_CLOUDINARY_PAR = "[Application] RESET_CLOUDINARY PAR";

  // ------------------------------------------------------------------------ Message Actions
  static showMessage(message: AppMessage) {
    return {
      type: ApplicationActions.MESSAGE,
      payload: message,
    };
  }

  // ------------------------------------------------------------------------ Page title
  static title(title: string[]) {
    return {
      type: ApplicationActions.TITLE,
      payload: title,
    };
  }

  // ------------------------------------------------------------------------ Sidebar Actions
  sidebarToggle(status?: boolean) {
    return {
      type: ApplicationActions.SIDEBAR_TOGGLE,
      payload: status,
    };
  }

  sidebarActiveGroup(groupId?: string) {
    return {
      type: ApplicationActions.SIDEBAR_ACTIVE_GROUP,
      payload: groupId,
    };
  }

  routeLoading(routeUrl?: string) {
    return {
      type: ApplicationActions.ROUTE_LOADING,
      payload: routeUrl,
    };
  }

  static showSuccessMessage(message: string) {
    return ApplicationActions.showMessage(
      new AppMessage(MessageType.SUCCESS, message)
    );
  }

  static showInfoMessage(message: string) {
    return ApplicationActions.showMessage(
      new AppMessage(MessageType.INFO, message)
    );
  }

  static showWarningMessage(message: string) {
    return ApplicationActions.showMessage(
      new AppMessage(MessageType.WARNING, message)
    );
  }

  static showErrorMessage(message: string) {
    return ApplicationActions.showMessage(
      new AppMessage(MessageType.ERROR, message)
    );
  }

  setCloudinary(
    mediaType: string,
    folderName: string,
    browseText: string,
    shape: string,
    extraZoomOut: boolean,
    redirectPath: string
  ) {
    return {
      type: ApplicationActions.SET_CLOUDINARY,
      payload: {
        mediaType: mediaType,
        folderName: folderName,
        browseText: browseText,
        shape: shape,
        extraZoomOut: extraZoomOut,
        redirectPath: redirectPath,
      },
    };
  }

  updateCloudinary(data: any) {
    return {
      type: ApplicationActions.UPDATE_CLOUDINARY,
      payload: data,
    };
  }

  clearCloudinaryData() {
    return {
      type: ApplicationActions.CLEAR_CLOUDINARY_DATA,
      payload: null,
    };
  }

  resetCloudinary() {
    return {
      type: ApplicationActions.RESET_CLOUDINARY,
      payload: null,
    };
  }

  static resetCloudinaryOrg() {
    return {
      type: ApplicationActions.RESET_CLOUDINARY_ORG,
      payload: null,
    };
  }

  static resetCloudinaryPar() {
    return {
      type: ApplicationActions.RESET_CLOUDINARY_PAR,
      payload: null,
    };
  }
}

/**
 * This action should be sent once during the initial
 * load of the application.  Useful for things like
 * fetching the application configuration from the API
 */
export const applicationStartup = createAction("[Application] Startup");

/**
 * Successful fetching of RcConfiguration from the API
 */
export const configSuccess = createAction(
  "[Config/API] Get Config Success",
  props<{ rcConfiguration: RCConfiguration }>()
);

export const configFailure = createAction(
  "[Config/API] Get Config Failure",
  props<{ err: any }>() // TODO: Type this
);
