import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { AppValidators } from "app/app.validators";

@Injectable()
export class RcAccountLoginForm {
  constructor(private fb: UntypedFormBuilder, private appValidators: AppValidators) {}

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          this.appValidators.emailValidator.bind(this),
        ]),
      ],
      password: [null, Validators.compose([Validators.required])],
    });
  }

  clean(formGroup: UntypedFormGroup): any {
    const formModel: any = formGroup.value;
    const account: any = {
      email: formModel.email as string,
      password: formModel.password as string,
    };

    return account;
  }
}
