export class ActingAs {
  as: string;
  hash?: string;
}

export class NewAccount {
  email: string;
  original_email: string;
  password: string;
}

export class Account {
  email: string;
  token: string;
  current_terms_accepted?: boolean;
  acting?: ActingAs;
  org?: Array<AccountFundriaser>;
  grp?: Array<AccountGroup>;
  par?: Array<AccountAccount>;
}

export class AccountFundriaser {
  contact?: any;

  name: string;
  signup_hash: string;
  switch_hashid: string;
  image: string;
  start_date: string;
  end_date: string;
  date_closed: string;
}

export class AccountGroup {
  fundraiserName: string;
  name: string;
  group_token: string;
}

export class AccountAccount {
  first_name: string;
  last_name: string;
  fundraiser: string;
  group: string;
  donate_hashid: string;
  image: string;
  switch_hashid?: string;
}
