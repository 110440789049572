import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ColumnSortedEvent } from "./rc-sortable.model";

@Injectable()
export class RcSortableService {
  private columnSortedSource = new Subject<ColumnSortedEvent>();

  columnSorted$ = this.columnSortedSource.asObservable();

  constructor() {}

  columnSorted(event: ColumnSortedEvent) {
    this.columnSortedSource.next(event);
  }
}
