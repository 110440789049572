<div id="footer" class="text-center">
  <div class="contact" *ngIf="showContact">
    <span [innerHtml]="'Need help? Contact us at '"></span>
    <a href="mailto:onboarding@raisecraze.com" class="onboarding">
      <strong [innerHtml]="'onboarding@raisecraze.com'"></strong>
    </a>
    <span [innerHtml]="' or&nbsp;(864)&nbsp;501&#8209;4265'"></span>
  </div>
  <span class="copyright">
    <span [innerHtml]="'&copy; '"></span>
    <span [innerHtml]="currentYear"></span>
    <span [innerHtml]="' Raise Craze, LLC. All rights&nbsp;reserved.'"></span>
  </span>
  <span>
    <a
      href="https://raisecraze.com/terms-conditions"
      target="_blank"
      class="terms"
    >
      <strong [innerHtml]="' Raise Craze\'s Terms of&nbsp;Service'"></strong>
    </a>
    <span [innerHtml]="' and '"></span>
    <a
      href="https://raisecraze.com/privacy-policy"
      target="_blank"
      class="privacy"
    >
      <strong [innerHtml]="'Privacy&nbsp;Policy'"></strong>
    </a>
  </span>
</div>
