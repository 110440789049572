<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="error-template">
        <h1 class="purple">404</h1>
        <h2>Page Not Found</h2>
        <div class="error-details">
          Unfortunately we're having trouble loading the page you are looking
          for. Please wait a moment and try again or use action below.
        </div>
        <div class="error-actions">
          <a [routerLink]="['/']" class="btn btn-primary btn-action">
            <i class="fa fa-home"></i>&nbsp;Take Me Home
          </a>
          <a [href]="getSupportMailTo()" class="btn btn-primary btn-action">
            <i class="fa fa-envelope"></i>&nbsp;Contact Support
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
