import {
  OnInit,
  OnDestroy,
  Component,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { AppService } from "app/app.service";
import { RcAccountActions } from "../rc-account.actions";
import { RcAccountCreateParForm } from "./rc-account-create.forms";
import { IAppState } from "app/app.state";
import { US_STATE_LIST, MONTH_LIST } from "app/rc-config";
import { SubscriptionManager } from "app/common/subscriptions/subscription.manager";
import * as moment from "moment";
import { BreadcrumbService } from "app/breadcrumb.service";
import { NewAccount } from "@raisecraze/model";

@Component({
  selector: "rc-account-create",
  templateUrl: "./rc-account-create.component.html",
  styleUrls: ["./rc-account-create.scss"],
})
export class RcAccountCreateComponent implements OnInit, OnDestroy {
  @Input() accountType: string;
  @Output() actionType: EventEmitter<any> = new EventEmitter();

  stateList = US_STATE_LIST;
  monthList = MONTH_LIST;

  form: UntypedFormGroup;
  busy: boolean = false;
  yearList: any;
  minAge: number = 13;
  showValidationError: boolean = false;
  showEmailMessage: boolean = false;
  _subs: SubscriptionManager = new SubscriptionManager();

  email_verify_result: string|unknown = null;
  email_verify_original: string = null;

  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
    private appService: AppService,
    private accountCreateParForm: RcAccountCreateParForm,
    private breadcrumbs: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbs.add("component", "RcAccountCreateComponent");

    this.form = this.accountCreateParForm.buildForm();
    this.yearList = this.getYearList();

    // Form Failure
    this._subs.push(
      this.actions$
        .pipe(
          ofType(
            RcAccountActions.createParAccountFailed,
            RcAccountActions.createOrgAccountFailed
          )
        )
        .subscribe((action: { status: number; error: {} }) => {
          this.busy = false;

          if (action.status === 400) {
            if ("email_verify" in action.error) {
              this.email_verify_result = action.error["email_verify"];
              this.email_verify_original = this.form.value["email"];
              this.form.controls["email"].setValue("");
              this.form.controls["email"].setErrors({
                message: this.email_verify_result,
              });
            } else {
              this.appService.displayAPIErrors(this.form, [action.error]);
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribeAll();
  }

  tapCreate($event?: any): void {
    if (this.busy) {
      return;
    }

    this.busy = true;

    if ($event) {
      $event.preventDefault();
    }

    if (this.form.valid) {
      const account: NewAccount = this.accountCreateParForm.clean(this.form);
      account.original_email = this.email_verify_original;
      if (this.accountCreateParForm.isValidAge(account, this.minAge)) {
        this.showValidationError = false;
        this.store.dispatch(
          RcAccountActions.createParAccount({ account: account })
        );
      } else {
        this.busy = false;
        this.showValidationError = true;
      }
    } else {
      this.appService.validateForm(this.form);
      this.busy = false;
    }
  }

  tapLogin($event: any): void {
    if ($event) {
      $event.preventDefault();
    }

    this.actionType.emit("login");
  }

  getYearList(): any {
    const now: moment.Moment = moment();
    const currentYear: number = now.year();
    const years: any[] = [];

    for (let i = currentYear; i > currentYear - 120; i--) {
      years[currentYear - i] = i;
    }

    // This adds an empty option to the start of the array
    // @see https://code.dvt-inc.com/raisecraze/raisecraze/issues/249
    years.unshift("");

    return years;
  }

  getAgeMessage(): string {
    return `You must be ${this.minAge} years old to create an account.`;
  }
}
