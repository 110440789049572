import { Injectable } from "@angular/core";
import { createAction, props } from "@ngrx/store";
import { Account, NewAccount } from "@raisecraze/model";

@Injectable()
export class RcAccountActions {
  static ACCOUNT_IMPERSONATE = "[Account] Impersonate";
  static SET_ACTING_AS = "[Account] Set Acting As";
  static ACCOUNT_GET = "[Account] Get";
  static ACCOUNT_GET_SUCCESS = "[Account] Get Success";
  static ACCOUNT_GET_FAILED = "[Account] Get Failed";
  static ACCOUNT_LOGIN = "[Account] Login";
  static ACCOUNT_LOGIN_SUCCESS = "[Account] Login Success";
  static ACCOUNT_LOGIN_FAILED = "[Account] Login Failed";
  static ACCOUNT_LOGOUT = "[Account] Logout";

  // ------------------------------------------------------------- changeEmail
  static changeEmail = createAction(
    "[Account] Change Email",
    props<{ newEmail: string }>()
  );

  static changeEmailSuccess = createAction(
    "[Account] Change Email Success",
    props<{ account: Account }>()
  );

  static changeEmailFailure = createAction(
    "[Account] Change Email Failure",
    props<{ status: number; error: any }>()
  );

  // ------------------------------------------------------------- participant create account
  static createParAccount = createAction(
    "[Account] Create Participant Account",
    props<{ account: NewAccount }>()
  );
  static createParAccountSuccess = createAction(
    "[Account] Create Participant Account Success",
    props<{ account: Account }>()
  );
  static createParAccountFailed = createAction(
    "[Account] Create Participant Account Failed",
    props<{ status: number; error: any }>()
  );

  // ------------------------------------------------------------- organizer create account
  static createOrgAccount = createAction(
    "[Account] Create Organizer Account",
    props<{ account: NewAccount }>()
  );
  static createOrgAccountSuccess = createAction(
    "[Account] Create Organizer Account Success",
    props<{ account: Account }>()
  );
  static createOrgAccountFailed = createAction(
    "[Account] Create Organizer Account Failed",
    props<{ status: number; error: any }>()
  );
  // ------------------------------------------------------------- changePassword
  static changePassword = createAction(
    "[Account] Change Password",
    props<{
      oldPassword: string;
      newPassword: string;
      newPasswordConfirm: string;
    }>()
  );

  static changePasswordSuccess = createAction(
    "[Account] Change Password Success",
    props<{ account: Account }>()
  );

  static changePasswordFailure = createAction(
    "[Account] Change Password Failure",
    props<{ status: number; error: any }>()
  );

  // ------------------------------------------------------------- acceptTerms
  static acceptTerms = createAction(
    "[Account] acceptTerms"
  );

  static acceptTermsSuccess = createAction(
    "[Account] acceptTerms Success",
    props<{ account: Account }>()
  );

  static acceptTermsFailure = createAction(
    "[Account] acceptTerms Failure",
    props<{ status: number; error: any }>()
  );

  // should clear the account data and trigger a reload of the account..
  static refreshAccount = createAction("[Account] Refresh Account");

  // ---------------------------------------------------- legacy,  should be replaced.
  static setActingAs(as: string, hash: string) {
    return {
      type: RcAccountActions.SET_ACTING_AS,
      payload: {
        as: as,
        hash: hash,
      },
    };
  }

  impersonate(token: string) {
    return {
      type: RcAccountActions.ACCOUNT_IMPERSONATE,
      payload: token,
    };
  }

  get(): any {
    return {
      type: RcAccountActions.ACCOUNT_GET,
      payload: null,
    };
  }

  getSuccess(userData: any): any {
    return {
      type: RcAccountActions.ACCOUNT_GET_SUCCESS,
      payload: userData,
    };
  }

  getFailed(err: any): any {
    return {
      type: RcAccountActions.ACCOUNT_GET_FAILED,
      payload: err,
    };
  }

  login(email: string, password: string): any {
    return {
      type: RcAccountActions.ACCOUNT_LOGIN,
      payload: {
        email: email,
        password: password,
      },
    };
  }

  loginSuccess(userData: any): any {
    return {
      type: RcAccountActions.ACCOUNT_LOGIN_SUCCESS,
      payload: userData,
    };
  }

  loginFailed(err: any): any {
    return {
      type: RcAccountActions.ACCOUNT_LOGIN_FAILED,
      payload: err,
    };
  }

  logout(): any {
    return {
      type: RcAccountActions.ACCOUNT_LOGOUT,
      payload: null,
    };
  }
}
