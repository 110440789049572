import { Component, OnInit } from "@angular/core";
import { getSupportMailTo } from "../../app.utils";

@Component({
  selector: "rc-notfound",
  templateUrl: "./rc-notfound.component.html",
  styleUrls: ["./rc-notfound.component.css"],
})
export class RcNotFoundComponent implements OnInit {
  constructor() {}
  ngOnInit() {}

  getSupportMailTo(mailtoPrefix: boolean = true) {
    return getSupportMailTo(mailtoPrefix);
  }
}
