import { logger } from "./../../rc-config";
import { Subscription } from "rxjs";

export class SubscriptionManager {
  subscriptionDict: any = {};
  subscriptionList: Array<Subscription> = [];

  get(index: number) {
    if (index != null) {
      return this.subscriptionList[index];
    }

    return null;
  }

  getByName(key: string) {
    if (key != null) {
      return this.subscriptionDict[key];
    }

    return null;
  }

  push(subscription: Subscription) {
    if (subscription != null) {
      this.subscriptionList.push(subscription);
    }
  }

  put(key: string, subscription: Subscription) {
    if (subscription != null && key != null) {
      if (this.subscriptionDict[key]) {
        logger.trace("Auto unsubscribe to duplicate: " + key);
        this.subscriptionDict[key].unsubscribe();
      }
      this.subscriptionDict[key] = subscription;
    }
  }

  unsubscribe(key: string) {
    if (key != null && this.subscriptionDict[key]) {
      try {
        this.subscriptionDict[key].unsubscribe();
      } catch (e) {
        logger.error("Unable to unsubscribe: " + key + " (" + e + ")");
      }
    }
  }

  unsubscribeAll() {
    for (const sub of Object.keys(this.subscriptionDict)) {
      try {
        this.subscriptionDict[sub].unsubscribe();
      } catch (e) {
        logger.error("Unable to unsubscribe all '" + sub + " (" + e + ")'");
      }
    }

    for (const sub of this.subscriptionList) {
      try {
        sub.unsubscribe();
      } catch (e) {
        logger.error("Unable to unsubscribe all (" + e + ")");
      }
    }
  }
}
