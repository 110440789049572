// Application State

import {
  ApplicationReducer,
  ApplicationState,
  DefaultApplicationState,
  CloudinaryState,
  OrgCloudinaryState,
  CloudinaryReducer,
} from "app/app.reducers";
import { ActionReducerMap } from "@ngrx/store/src/models";
import { Account } from "@raisecraze/model";
import {
  RcAuthReducer,
  RcAccountReducer,

} from "./rc-modules/rc-account/rc-account.reducer";
import { rcConfigurationReducer } from "./app.reducers";

export class RCConfiguration {
  cloudinary?: {
    cloud_name: string;
    upload_preset: string;
  };
  contact?: {
    support: string;
  };
  sentry?: {
    public_dsn: string;
    release: string;
  };
  flags?: any;
}

export interface IAppState {
  auth: { token: string };
  config: RCConfiguration;
  account: Account;
  app: ApplicationState;
  media: CloudinaryState;
}

export class AppState implements IAppState {
  auth: { token: string };
  config: RCConfiguration;
  account: Account;
  app: ApplicationState;
  media: CloudinaryState;
}

export const DefaultAppState: AppState = {
  auth: null,
  config: {},
  account: null,
  app: DefaultApplicationState,
  media: OrgCloudinaryState, // This is changes in the org/par components as appropiate.
};

export const reducers: ActionReducerMap<AppState> = {
  auth: RcAuthReducer,
  config: rcConfigurationReducer,
  account: RcAccountReducer,
  app: ApplicationReducer,
  media: CloudinaryReducer,
};

// Selectors
export const pageTitleSelector = (state: IAppState) => state.app.title;
export const pageHeaderSelector = (state: IAppState) =>
  state.app.title[state.app.title.length - 1];
export const sidebarSelector = (state: IAppState) => state.app.sidebar;
export const routeLoadingSelector = (state: IAppState) =>
  state.app.route_loading;
export const rcConfigSelector = (state: IAppState) => state.config;
export const rcConfigFlagsSelector = (state: IAppState) => state.config?.flags;
export const selectAuth = (state: AppState) => state.auth?.token;
export const selectAccount = (state: AppState) => state.account;
