import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import {
  SET_ERROR,
  VALIDATION_ERROR,
  SERVER_ERROR,
  AUTH_ERROR,
  UNKNOWN_ERROR,
  FATAL_ERROR,
} from "./app.actions";
import { logger } from "./rc-config";
import * as moment from "moment";
import { WindowRef } from "./common/window/window";
import { FundraiserDetails } from "@raisecraze/org-bl";
import { FundraiserMetaExtraOptions } from "@raisecraze/model";

export function getSupportMailTo(mailtoPrefix: boolean = true) {
  const email = "support@raisecraze.com"; // TODO: pull from confing..  should be in state

  if (!mailtoPrefix) {
    return email;
  }

  return `mailto:${email}`;
}

export function stringAsDate(dateString: String) {
  if (dateString == null) {
    return null;
  }

  const dateParts = dateString.split("-");
  const date = {
    year: Number(dateParts[0]),
    month: Number(dateParts[1]),
    day: Number(dateParts[2]),
  };

  return date;
}

export function dateAsString(dateValue: any): string {
  if (dateValue == null) {
    return null;
  }

  return dateValue == null
    ? ""
    : `${dateValue["year"]}-${dateValue["month"]}-${dateValue["day"]}`;
}

export function getMinDate(
  form: UntypedFormGroup,
  property: string,
  defaultDate?: Date
) {
  const now = moment();
  const controlValue = form.get(property).value;
  if (controlValue == null) {
    return { year: now.year(), month: now.month() + 1, day: now.date() };
  }

  const controlDate = new Date(
    controlValue["year"],
    controlValue["month"] - 1,
    controlValue["day"]
  );
  if (now.isBefore(moment(controlDate))) {
    return { year: now.year(), month: now.month() + 1, day: now.date() };
  }

  if (defaultDate != null) {
    return defaultDate;
  }

  return controlValue;
}

export function causeAsArray(causeJson: string): string[] {
  if (causeJson == null) {
    return [];
  }

  try {
    const cause = JSON.parse(causeJson);
    if (cause.type === "list") {
      const array = cause.value;
      const cause_list = [];
      if (array.length > 0) {
        cause_list.push(array[0]);
      }

      if (array.length > 1) {
        cause_list.push(array[1]);
      }

      if (array.length > 2) {
        cause_list.push(array[2]);
      }

      return cause_list;
    } else if (cause.type == "website") {
      return [
        "You have requested a custom design.  These fields are not active. Contact support for more information",
      ];
    } else if (cause.type == "paragraph") {
      return [
        "You have requested a custom design.  These fields are not active. Contact support for more information",
      ];
    }
  } catch (e) {
    logger.error("Unable to parse cause list " + causeJson);
  }

  return [causeJson];
}

export function causeAsJson(causes: String[]): string {
  const causeJson = {
    type: "list",
    value: causes.filter((c) => c),
  };

  return JSON.stringify(causeJson);
}

export function mergeExtraOptions(
  fundraiser: FundraiserDetails
): FundraiserMetaExtraOptions {
  const defaultExtraOptions: FundraiserMetaExtraOptions = JSON.parse(
    JSON.stringify(new FundraiserMetaExtraOptions())
  );

  if (fundraiser && fundraiser.meta) {
    return Object.assign(
      {},
      defaultExtraOptions,
      fundraiser.meta.extra_options
    );
  }

  return defaultExtraOptions;
}

export function jumpToFragment(router: Router) {
  router.events.subscribe((s) => {
    if (s instanceof NavigationEnd) {
      const tree = router.parseUrl(router.url);
      if (tree.fragment) {
        const element = document.querySelector("#" + tree.fragment);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView();
          }, 50);
        }
      }
    }
  });
}

export function outletRedirectPath(route: ActivatedRoute) {
  let parent = route.parent;
  let path = parent.routeConfig.path;

  // Check for parameters in the route. They will need to be filled in if any exist.
  if (path.indexOf(":") > -1) {
    let params = parent["params"]["value"];

    Object.keys(params).map((key) => {
      let paramName = `:${key}`;
      path = path.replace(paramName, params[key]);
    });
  }

  return "/" + path;
}

export function errorHandler(type, err, store: Store<any>) {
  let errors;

  try {
    if (err.status === 400) {
      errors = {
        type: VALIDATION_ERROR,
        data: err.json() as {},
      };
    } else if (err.status === 500) {
      errors = {
        type: SERVER_ERROR,
        data: err,
      };
    } else if (err.status === 403) {
      errors = {
        type: AUTH_ERROR,
        data: err,
      };
    } else {
      errors = {
        type: UNKNOWN_ERROR,
        data: err,
      };
    }
  } catch (ex) {
    errors = {
      type: FATAL_ERROR,
      data: err,
    };
  }

  store.dispatch({
    type: type,
    payload: {
      fundraiser: null,
      errors: errors,
    },
  });

  store.dispatch({
    type: SET_ERROR,
    payload: errors,
  });

  if (err === null) {
    return;
  }
}

export function isRouteActive(router: Router, baseRoute: any, exact = false) {
  if (baseRoute instanceof Array) {
    baseRoute = baseRoute.join("/");
  }

  if (exact) {
    return router.url === baseRoute;
  }

  return router.url.indexOf(baseRoute) !== -1;
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function copyText($event: any, fieldId: string) {
  $event.preventDefault();
  const field: any = document.getElementById(fieldId);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const editable = field.contentEditable;
    const readOnly = field.readOnly;

    // convert to editable with readonly to stop iOS keyboard opening
    field.contentEditable = true;
    field.readOnly = true;

    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(field);

    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    field.setSelectionRange(0, field.value.length);

    // restore contentEditable/readOnly to original state
    field.contentEditable = editable;
    field.readOnly = readOnly;
  } else {
    field.select();
  }

  document.execCommand("copy");
  field.setSelectionRange(0, 0);
  field.blur();
}

export function isMedium(windowRef: WindowRef): boolean {
  const windowWidth: any = windowRef.nativeWindow.innerWidth;
  if (windowWidth > 768) {
    // Based off of the bootstrap 4 media query for large devices
    return false;
  }

  return true;
}

export function isMobile(windowRef: WindowRef): boolean {
  const windowWidth: any = windowRef.nativeWindow.innerWidth;
  if (windowWidth > 576) {
    // Based off of the bootstrap 4 media query for small devices
    return false;
  }

  return true;
}

export function getInitials(firstName: string, lastName?: string): string {
  if (firstName) {
    const firstNameInitial: string = firstName.substring(0, 1).toUpperCase();
    if (lastName) {
      const lastNameInitial: string = lastName.substring(0, 1).toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    }

    return firstNameInitial;
  }

  return null;
}