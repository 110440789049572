<div class="wrapper-fluid" (keyup.enter)="tapLogin($event)">
  <h2 class="mb-3" [innerHtml]="'So Glad You\'re&nbsp;Back!'"></h2>

  <div class="row" *ngIf="hasError">
    <div class="col-sm-10 offset-sm-1">
      <div
        class="alert alert-danger"
        [innerHtml]="'Oh no! This email/password combination was not found.'"
      ></div>
      <p class="ms-3 me-3">
        <span
          [innerHtml]="
            'To protect your privacy, we do not retain student & participant accounts from year to year.  If you are a returning student or player and you have not yet registered for this year\'s fundraiser, please select \'Create&nbsp;Account\'&nbsp;instead.'
          "
        ></span>
      </p>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-sm-10 offset-sm-1">
      <form novalidate [formGroup]="form">
        <div class="form-group row">
          <rc-text-field
            class="col-12"
            type="email"
            [form]="form"
            property="email"
            label="Email"
            maxlength="254"
          ></rc-text-field>
        </div>
        <div class="form-group row">
          <rc-text-field
            class="col-12"
            type="password"
            [form]="form"
            property="password"
            label="Password"
            maxlength="254"
          ></rc-text-field>
        </div>
      </form>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-sm-10 offset-sm-1">
      <div class="me-3 ms-3 mb-3 text-end">
        <a href="/help/password/"
          ><strong [innerHtml]="'FORGOT PASSWORD?'"></strong
        ></a>
      </div>

      <div class="form-group clearfix buttons mb-0">
        <a
          href="#"
          class="create float-start d-none d-md-block"
          [ngClass]="{ disabled: busy }"
          (click)="tapCreate($event)"
        >
          <strong [innerHtml]="'Create Account'"></strong>
        </a>
        <button
          id="id_login"
          class="btn btn-primary purple float-end"
          type="button"
          (click)="tapLogin($event)"
          [disabled]="busy"
        >
          <span [innerHtml]="'Login'" *ngIf="!busy"></span>
          <rc-spinner [isBusy]="busy"></rc-spinner>
        </button>
        <div class="d-block d-md-none mt-2 text-center">
          <a
            href="#"
            class="create"
            [ngClass]="{ disabled: busy }"
            (click)="tapCreate($event)"
          >
            <strong [innerHtml]="'Create Account'"></strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
