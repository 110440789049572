import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first, filter } from "rxjs/operators";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import {
  selectAccount,
  selectAuth,
  IAppState,
  rcConfigSelector,
  RCConfiguration,
} from "./app.state";
import { Account, AccountGroup } from "@raisecraze/model";
import { RcAccountActions } from "./rc-modules/rc-account/rc-account.actions";
import { applicationStartup } from "./app.actions";
import { SubscriptionManager } from "./common/subscriptions/subscription.manager";
import { BreadcrumbService } from "./breadcrumb.service";

@Component({
  selector: "rc-app-root",
  templateUrl: "./app.root.component.html",
  styleUrls: ["./app.component.scss"],
})
export class RcAppRootComponent implements OnInit {
  auth$: Observable<string> = this.store.select(selectAuth);
  config$: Observable<RCConfiguration> = this.store.select(rcConfigSelector);

  loading: boolean = true; // show loading spinnger until router activates
  inSetup: boolean = false; // this will be true when in setup and the user does not need to be authorized

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    private accountActions: RcAccountActions,
    private breadcrumbs: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(applicationStartup());

    if (this.cookieService.check("rc-impersonate")) {
      this.breadcrumbs.add("auth", "Impersonated");

      const impersonate: string = this.cookieService.get("rc-impersonate");
      const splits = impersonate.split(":"); // token,type,hash
      const token = splits[0];
      this.store.dispatch(this.accountActions.impersonate(token));

      if (splits.length > 1) {
        // 2020-06-29 - not yet supported by django admin impersonate.
        this.store.dispatch(RcAccountActions.setActingAs(splits[1], splits[2]));
      }

      this.cookieService.delete("rc-impersonate", "/");
      this.router.navigate(["/"]);
    }

    // TODO! Review this,  somehow need to allow acces to the setup stuff.
    // I could not find a way to grab the current route in here....
    // we might have to move to 'gasp' a guard
    this.router.events.subscribe((val) => {
      this.inSetup = location.href.indexOf("create") !== -1;
    });

    this.route.queryParamMap
      .pipe(
        filter((queryParams) => queryParams && queryParams.has("logout")),
        first()
      )
      .subscribe((queryParams) => {
        this.breadcrumbs.add("auth", "Logged out via queryParam");
        this.store.dispatch(this.accountActions.logout());
        this.router.navigate(["/"]);
      });
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  isAuthorized(auth: string) {
    return this.inSetup || auth;
  }
}

@Component({
  selector: "rc-app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [NgbTooltipConfig],
})
export class RcAppComponent implements OnInit, OnDestroy {
  canSelectApp = false;

  account$: Observable<Account> = this.store.select(selectAccount);

  private _subs: SubscriptionManager = new SubscriptionManager();

  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private config: NgbTooltipConfig
  ) {
    this.config.container = "body";
    this.config.triggers = "hover";
    this.config.placement = "top";
    this.config.autoClose = true;
  }

  ngOnInit(): void {
    this.store.dispatch(RcAccountActions.refreshAccount());

    // once the account is loaded navigate to the correct place.
    this._subs.push(
      this.account$
        .pipe(first((account) => !!account?.email))
        .subscribe((account: Account) => {
          if (
            account?.acting?.as === "org" &&
            account.org?.find(
              (o) =>
                o.signup_hash.toUpperCase() ===
                account.acting.hash.toUpperCase()
            )
          ) {
            this.router.navigate([account.acting.as]);
          } else if (
            account?.acting?.as === "par" &&
            account?.par?.find(
              (o) =>
                o.donate_hashid.toUpperCase() ===
                account.acting.hash.toUpperCase()
            )
          ) {
            this.router.navigate([account.acting.as]);
          } else if (account?.org?.length > 0) {
            this.router.navigate(["/org"]);
          } else if (account?.par?.length > 0) {
            this.router.navigate(["/par"]);
          } else if (account?.grp?.length > 0) {
            const group: AccountGroup = account.grp[0];
            window.location.href = `/fun/group/${group.group_token}/`;
          } else {
            window.location.href = "https://raisecraze.com/create";
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribeAll();
  }
}
