<div class="loading" [ngClass]="{ full: overlay }">
  <div [ngClass]="{ 'loading-wrapper': overlay }">
    <a
      href="https://www.raisecraze.com/"
      title="Raise Craze"
      class="logo"
      *ngIf="showLogo && homeLink"
    >
      <img src="/static/gen1/img/logo.large.png" alt="Raise Craze" />
    </a>
    <div class="logo" *ngIf="showLogo && !homeLink">
      <img src="/static/gen1/img/logo.large.png" alt="Raise Craze" />
    </div>
    <div class="spinner" *ngIf="showSpinner && icon == null"></div>
    <div
      class="icon"
      [ngClass]="icon"
      *ngIf="!showSpinner && icon != null"
    ></div>
    <p [innerHtml]="message"></p>
    <ng-content select=".extra"></ng-content>
  </div>
</div>
