<div class="form-group-inner" [formGroup]="form" [ngClass]="getDivClass()">
  <label
    for="{{ property }}"
    class="control-label"
    [ngStyle]="getLabelStyle()"
    *ngIf="floatingLabel"
    >{{ label }}</label
  >
  <label
    for="{{ property }}"
    class="control-label-static"
    *ngIf="!floatingLabel && label != null"
    >{{ label }}</label
  >
  <p class="form-text" [innerHtml]="hint" *ngIf="hint"></p>
  <div class="input-group d-flex align-items-stretch">
    <ng-content select=".prefix"></ng-content>
    <textarea
      #textField
      [id]="getId()"
      formControlName="{{ property }}"
      class="form-control"
      [attr.maxlength]="255"
      (input)="onInputEntry($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
      *ngIf="property && inputType=='textarea'"
    ></textarea>
    <input
      #textField
      [id]="getId()"
      [type]="inputType"
      [mask]="inputMask"
      [dropSpecialCharacters]="false"
      formControlName="{{ property }}"
      class="form-control"
      [ngClass]="{ 'has-suffix': doShowCharCounter() }"
      [attr.maxlength]="getMaxLength()"
      (input)="onInputEntry($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
      *ngIf="property && inputType!='textarea'"
    />
    <input
      #textField
      [type]="inputType"
      [formControl]="control"
      [mask]="inputMask"
      [dropSpecialCharacters]="false"
      class="form-control"
      [ngClass]="{ 'has-suffix': doShowCharCounter() }"
      [attr.maxlength]="getMaxLength()"
      (input)="onInputEntry($event)"
      (blur)="onBlur()"
      (input)="onInputEntry($event)"
      (focus)="onFocus()"
      *ngIf="control"
    />
    <div
      class="suffix d-flex input-group-append input-group-append-muted"
      *ngIf="doShowCharCounter()"
    >
      <span [innerHtml]="getMaxLengthText()"></span>
    </div>
    <ng-content select=".suffix"></ng-content>
  </div>
  <rc-control-messages
    [control]="form.controls[property]"
    class="form-control-feedback"
    *ngIf="property"
  ></rc-control-messages>
  <rc-control-messages
    [control]="control"
    class="form-control-feedback"
    *ngIf="control"
  ></rc-control-messages>
  <ng-content select=".message.form-control-feedback"></ng-content>
  <ng-content select=".form-text.text-muted"></ng-content>
</div>
