import { Injectable } from "@angular/core";

import * as Sentry from "@sentry/browser";

@Injectable()
export class BreadcrumbService {
  constructor() {}

  //               level: 'info', // Sentry.Severity.Info,

  add(category: string, message: string, level: Sentry.SeverityLevel = 'info' ) {
    Sentry.addBreadcrumb({
      category: category,
      message: message,
      level: level,
    });
  }
}
