import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { Title } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import {
  map,
  withLatestFrom,
  mergeMap,
  catchError,
  tap,
  filter,
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Action } from "./upgrade";
import {
  ApplicationActions,
  AppMessage,
  applicationStartup,
  configSuccess,
  configFailure,
} from "./app.actions";
import { MessageType } from "./app.enums";
import { Store } from "@ngrx/store";
import { IAppState, sidebarSelector, RCConfiguration } from "./app.state";
import { RCConfigurationService } from "./config.service";

@Injectable()
export class RCConfigurationEffects {
  /**
   * calls configurationService loadConfig to get the application configuration
   */
  loadConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applicationStartup),
      mergeMap(() =>
        this.configurationService.loadConfig().pipe(
          map((rcConfiguration: RCConfiguration) =>
            configSuccess({ rcConfiguration })
          ),
          catchError((err) => of(configFailure({ err: err })))
        )
      )
    )
  );

  /**
   * Listens for configSuccess and then used configuration values to intizlize Sentry
   */
  initSentry$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(configSuccess),
        filter((action) => !!action?.rcConfiguration?.sentry?.public_dsn),
        tap((action) =>
          Sentry.init({
            dsn: action?.rcConfiguration?.sentry?.public_dsn,
            release: action?.rcConfiguration?.sentry?.release,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private configurationService: RCConfigurationService
  ) {}
}

@Injectable()
export class AppEffects {
  displayMessage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.MESSAGE),
      map((action: Action) => {
        const appMessage: AppMessage = action.payload;
        switch (appMessage.type) {
          case MessageType.SUCCESS:
            this.toastr.success(appMessage.message);
            break;
          case MessageType.INFO:
            this.toastr.info(appMessage.message);
            break;
          case MessageType.WARNING:
            this.toastr.warning(appMessage.message);
            break;
          case MessageType.ERROR:
            this.toastr.error(appMessage.message);
            break;
        }
        return { type: "NO_ACTION" };
      })
    )
  );

  setTitle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.TITLE),
      withLatestFrom(this.store.select(sidebarSelector)),
      map(([action, sidebar]) => {
        if (action.payload.length) {
          this.titleService.setTitle(action.payload.join(" - "));
        }

        return { type: "NO_ACTION" };
      })
    )
  );

  constructor(
    private actions$: Actions<Action>,
    private toastr: ToastrService,
    private titleService: Title,
    private store: Store<IAppState>,
  ) {}
}
