import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RcSpinnerComponent } from "./rc-spinner/rc-spinner.component";
import { ChangeNamePipe } from './change-name.pipe';
import { IsSchoolLikePipe } from './is-school-like.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [RcSpinnerComponent, ChangeNamePipe, IsSchoolLikePipe],
  exports: [RcSpinnerComponent, ChangeNamePipe, IsSchoolLikePipe],
})
export class UiModule {}
