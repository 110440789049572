import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NewAccount } from "@raisecraze/model";

@Injectable()
export class RcAccountService {
  private api_url = "/api-v1/account/";

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get(this.api_url);
  }

  create(account: NewAccount, original_email: string = null) {
    return this.http.post(this.api_url + "create/", account);
  }

  login(email: string, password: string) {
    return this.http.post(this.api_url, {
      username: email,
      password: password,
    });
  }

  saveAccount(data: any): Observable<any> {
    return this.http.put(this.api_url, data);
  }

  acceptTerms(): Observable<any> {
    return this.http.put(this.api_url, {
      accept_terms: true
    }) as Observable<any>;
  }
}
