import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RcWidgetModule } from "app/rc-widgets/rc-widget.module";
import { RcAccountAuthComponent } from "./rc-account-auth/rc-account-auth.component";
import { RcAccountLoginComponent } from "./rc-account-login/rc-account-login.component";
import { RcAccountCreateComponent } from "./rc-account-create/rc-account-create.component";
import { RcAccountFormComponent } from "./rc-account-form/rc-account-form.component";
import { RcAccountActions } from "./rc-account.actions";
import { RcAccountService } from "./rc-account.service";
import { RcAccountLoginForm } from "./rc-account-login/rc-account-login.forms";
import { RcAccountCreateParForm } from "./rc-account-create/rc-account-create.forms";
import {
  RcAccountEmailForm,
  RcAccountPasswordForm,
} from "./rc-account-form/rc-account-form.forms";
import { UiModule } from "@raisecraze/ui";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RcWidgetModule,
    UiModule,
  ],
  declarations: [
    RcAccountAuthComponent,
    RcAccountLoginComponent,
    RcAccountCreateComponent,
    RcAccountFormComponent,
  ],
  providers: [
    RcAccountActions,
    RcAccountService,
    RcAccountLoginForm,
    RcAccountCreateParForm,
    RcAccountEmailForm,
    RcAccountPasswordForm,
  ],
  exports: [
    RcAccountAuthComponent,
    RcAccountLoginComponent,
    RcAccountCreateComponent,
    RcAccountFormComponent,
  ],
})
export class RcAccountModule {}
