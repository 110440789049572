import * as Sentry from "@sentry/browser";
import { environment } from "../environments/environment";

function _window(): any {
  // return the global native browser window object
  return window;
}

export class Logger {
  public constructor(
    private is_trace: boolean = false,
    private is_debug: boolean = false,
    private is_info: boolean = false,
    private is_warn: boolean = true,
    private is_error: boolean = true
  ) {}

  public setLevels(trace, debug, info, warn, error) {
    this.is_trace = trace;
    this.is_debug = debug;
    this.is_info = info;
    this.is_warn = warn;
    this.is_error = error;
  }

  public setLevel(level: string) {
    if (level === "trace") {
      this.setLevels(true, true, true, true, true);
    } else if (level === "debug") {
      this.setLevels(false, true, true, true, true);
    } else if (level === "info") {
      this.setLevels(false, false, true, true, true);
    } else if (level === "warn") {
      this.setLevels(false, false, false, true, true);
    } else if (level === "error") {
      this.setLevels(false, false, false, false, true);
    }
  }

  public trace(message: any, ...optionalParams: any[]) {
    if (this.is_trace) {
      console.log(`TRACE: ${message}`, optionalParams);
    }
  }

  public debug(message: any, ...optionalParams: any[]) {
    if (this.is_debug) {
      console.log(`DEBUG: ${message}`, optionalParams);
    }
  }

  public info(message: any, ...optionalParams: any[]) {
    if (this.is_info) {
      console.log(`INFO: ${message}`, optionalParams);
    }
  }

  public warn(message: any, ...optionalParams: any[]) {
    if (this.is_warn) {
      console.log(`WAN: ${message}`, optionalParams);
    }
  }

  public error(message: any, optionalParams?: Map<string, any>) {
    Sentry.withScope((scope) => {
      if (optionalParams) {
        const extras: any = {};

        optionalParams.forEach((value: any, key: string) => {
          if (key !== null && value !== null) {
            extras[key] = value;
          }
        });

        scope.setExtras(extras);
      }

      Sentry.captureException(message);
    });

    if (this.is_error) {
      console.log(`ERROR: ${message}`, optionalParams);
    }
  }
}

export const logger = environment.production
  ? new Logger(false, false, false, false, false)
  : new Logger(false, true, true, true, true);

export const CURRENT_RELEASE_NOTES: number = 20210722;

export const AGE_TYPE_LIST = {
  // ALSO See org-setup-model SetupStepAgeGroup TODO: Combine these lists
  elementary: "Elementary",
  middle: "Middle school",
  "k-8": "K-8",
  "k-12": "K-12",
  high: "High school",
  college: "College",
  preschool: "Preschool",
  other: "Other",
};

// john - i have no idea how to use the above constants here.
export const CLIENT_TYPE_LIST = {
  1: "School",
  2: "Sports Team",
  6: "Other",
  7: "Club",
  8: "Band",
  9: "Class or Grade",
  10: "Troop",
  11: "Youth Group",
};

export const US_STATE_LIST: any = {
  "": "",
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  AS: "American Samoa",
  GU: "Guam",
  MP: "Northern Mariana Islands",
  PR: "Puerto Rico",
  VI: "Virgin Islands",
  AA: "Armed Forces Americas",
  AE: "Armed Forces Europe",
  AP: "Armed Forces Pacific",
};

export const MONTH_LIST: any = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};
