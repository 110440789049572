import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of, Observable } from "rxjs";
import {
  mergeMap,
  map,
  catchError,
  filter,
  withLatestFrom,
} from "rxjs/operators";
import { RcAccountActions } from "./rc-account.actions";
import { RcAccountService } from "./rc-account.service";
import { Action } from "../../upgrade";
import { ApplicationActions, AppMessage } from "app/app.actions";
import { MessageType } from "app/app.enums";
import { Account } from "@raisecraze/model";
import { IAppState, rcConfigSelector } from "app/app.state";
import * as Sentry from "@sentry/browser";
import { IFundraiserDetails } from "@raisecraze/model";

@Injectable()
export class RcAccountEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RcAccountActions.ACCOUNT_GET),
      mergeMap((action) => {
        return this.accountService.get().pipe(
          map((userData: any) => this.accountActions.getSuccess(userData)),
          catchError((error) => of(this.accountActions.getFailed(error)))
        );
      })
    )
  );

  /**
   * Listens for account and rc-config then adds the user info to the global Sentry Scope
   */
  configureSentryUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RcAccountActions.ACCOUNT_GET_SUCCESS),
        withLatestFrom(this.store.select(rcConfigSelector)),
        filter(
          ([action, rcConfiguration]) => !!rcConfiguration?.sentry?.public_dsn
        ),
        map(([action, rcConfiguration]) => {
          Sentry.configureScope((scope: Sentry.Scope) => {
            scope.setUser({
              email: action.payload?.email,
            });
          });
        })
      ),
    { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RcAccountActions.ACCOUNT_LOGIN),
      mergeMap((action) => {
        return this.accountService
          .login(action.payload.email, action.payload.password)
          .pipe(
            map((userData: any) => this.accountActions.loginSuccess(userData)),
            catchError((error) => of(this.accountActions.loginFailed(error)))
          );
      })
    )
  );

  createOrgAccountFailed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RcAccountActions.createOrgAccountFailed.type),
      map((action) =>
        ApplicationActions.showMessage(
          new AppMessage(
            MessageType.ERROR,
            "An error occured while creating your account"
          )
        )
      )
    )
  );

  createParAccountFailed$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RcAccountActions.createParAccountFailed.type),
      map((action) =>
        ApplicationActions.showMessage(
          new AppMessage(
            MessageType.ERROR,
            "An error occured while creating your account"
          )
        )
      )
    )
  );

  // ------------------------------------------------------------------------------------- refresh (same as GET just new style. can combine)
  refresh$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(RcAccountActions.refreshAccount),
      mergeMap((action) => {
        return this.accountService.get().pipe(
          map((userData: any) => this.accountActions.getSuccess(userData)),
          catchError((error) => of(this.accountActions.getFailed(error)))
        );
      })
    )
  );

  // ------------------------------------------------------------------------------------- changeEmail
  changeEmail$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(RcAccountActions.changeEmail),
      mergeMap((action) => {
        const data: any = {
          email: action.newEmail,
        };

        return this.accountService.saveAccount(data).pipe(
          map((result: Account) =>
            RcAccountActions.changeEmailSuccess({ account: result })
          ),
          catchError((error) =>
            of(
              RcAccountActions.changeEmailFailure({
                status: error.status,
                error: error.error,
              })
            )
          )
        );
      })
    )
  );

  // ------------------------------------------------------------------------------------- createParticipantAccount
  createParticipantAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(RcAccountActions.createParAccount),
      mergeMap((action) => {
        return this.accountService.create(action.account).pipe(
          map((result: Account) =>
            RcAccountActions.createParAccountSuccess({ account: result })
          ),
          catchError((error) =>
            of(
              RcAccountActions.createParAccountFailed({
                status: error.status,
                error: error.error,
              })
            )
          )
        );
      })
    )
  );

  // ------------------------------------------------------------------------------------- createOrganizerAccount
  createOrganizerAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(RcAccountActions.createOrgAccount),
      mergeMap((action) => {
        return this.accountService.create(action.account).pipe(
          map((result: Account) =>
            RcAccountActions.createOrgAccountSuccess({ account: result })
          ),
          catchError((error) =>
            of(
              RcAccountActions.createOrgAccountFailed({
                status: error.status,
                error: error.error,
              })
            )
          )
        );
      })
    )
  );

  // ------------------------------------------------------------------------------------- changePassword
  changePassword$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(RcAccountActions.changePassword),
      mergeMap((action) => {
        const data: any = {
          oldPassword: action.oldPassword,
          newPassword: action.newPassword,
          newPasswordConfirm: action.newPasswordConfirm,
        };

        return this.accountService.saveAccount(data).pipe(
          map((result: Account) =>
            RcAccountActions.changePasswordSuccess({ account: result })
          ),
          catchError((error) =>
            of(
              RcAccountActions.changePasswordFailure({
                status: error.status,
                error: error.error,
              })
            )
          )
        );
      })
    )
  );

  // ------------------------------------------------------------------------------------- changePassword
  acceptTerms$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(RcAccountActions.acceptTerms),
      mergeMap((action) => {

        return this.accountService.acceptTerms().pipe(
          map((result: Account) =>
            RcAccountActions.acceptTermsSuccess({ account: result })
          ),
          catchError((error) =>
            of(
              RcAccountActions.acceptTermsFailure({
                status: error.status,
                error: error.error,
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions<Action>,
    private store: Store<IAppState>,
    private accountActions: RcAccountActions,
    private accountService: RcAccountService
  ) {}
}
