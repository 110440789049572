import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "rc-loader",
  templateUrl: "./rc-loader.component.html",
  styleUrls: ["./rc-loader.component.css"],
})
export class RcLoaderComponent implements OnInit {
  @Input() message: string = "Loading...";
  @Input() showLogo: boolean = true;
  @Input() homeLink: boolean = true;
  @Input() overlay: boolean = true;
  @Input() showSpinner: boolean = true;
  @Input() icon: string = null;

  constructor() {}
  ngOnInit() {}
}
