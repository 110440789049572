import { OnInit, OnDestroy, Component } from "@angular/core";
import { Router, Event } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IAppState, selectAuth } from "app/app.state";
import { SubscriptionManager } from "app/common/subscriptions/subscription.manager";
import { BreadcrumbService } from "app/breadcrumb.service";

@Component({
  selector: "rc-account-auth",
  templateUrl: "./rc-account-auth.component.html",
  styleUrls: ["./rc-account-auth.scss"],
})
export class RcAccountAuthComponent implements OnInit, OnDestroy {
  auth$: Observable<string> = this.store.select(selectAuth);

  showLogin: boolean = true;
  showCreate: boolean = false;
  _subs: SubscriptionManager = new SubscriptionManager();

  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private breadcrumbs: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbs.add("component", "RcAccountAuthComponent");

    this._subs.push(
      this.router.events.subscribe((route: Event) => {
        if ("url" in route) {
          const url: string = route["url"];
          this.showCreate = url.includes("join");
        }

        this.showLogin = !this.showCreate;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribeAll();
  }

  handleActionType(actionType: string): void {
    if (actionType === "login") {
      this.showLogin = true;
      this.showCreate = false;
    } else {
      this.showLogin = false;
      this.showCreate = true;
    }
  }
}
