import { FundraiserDetails, CLIENT_TYPE_SCHOOL, CLIENT_TYPE_CLASS_GRADE, Fundraiser, Group} from "./org-model";

export function isSchoolLike(fundraiser: Fundraiser | FundraiserDetails): boolean {
  if (!fundraiser) {
    return false;
  }

  const client_type =
    (Object.prototype.hasOwnProperty.call(fundraiser, 'details')) ?
    (fundraiser as Fundraiser)?.details?.client?.client_type :
    (fundraiser as FundraiserDetails).client?.client_type

  switch (client_type) {
    case CLIENT_TYPE_SCHOOL:
    case CLIENT_TYPE_CLASS_GRADE:
      return true;
    default:
      break;
  }

  return false;
}

export function changeName(value: string, fundraiser: Fundraiser | FundraiserDetails): string {
  // Names for school and class/grade.
  const group_labels =
    (Object.prototype.hasOwnProperty.call(fundraiser, 'details')) ?
    (fundraiser as Fundraiser)?.details?.options?.group_labels :
    (fundraiser as FundraiserDetails).options?.group_labels

  const value_lower = value.toLocaleLowerCase();
  let changed_name = group_labels ? group_labels[value_lower] : value;

  if (changed_name) {
    return adjustCasing(value, changed_name)
  } else {
    return value
  }

}

/**
* Adjusts the casing of the `newValue` parameter to match the casing of the `original` parameter.
*
* @param original - The original string used as the reference for the casing.
* @param newValue - The new value that will be returned with adjusted casing.
* @returns The `newValue` string with the same casing as the `original` string.
*
* If `original` is all lowercase, `newValue` will be returned in all lowercase.
* If `original` has a capitalized first letter, `newValue` will be returned with a capitalized first letter.
* If `original` is all uppercase, `newValue` will be returned in all uppercase.
* If `original` has mixed casing, `newValue` will be returned as is.
*/
function adjustCasing(original: string, newValue: string): string {
  // Check if original is all lowercase
  if (original === original.toLowerCase()) {
    return newValue.toLowerCase();
  }

  // Check if original is all uppercase
  else if (original === original.toUpperCase()) {
    return newValue.toUpperCase();
  }

  // Check if original starts with a capital letter
  else if (original[0] === original[0].toUpperCase()) {
    return newValue[0].toUpperCase() + newValue.slice(1);
  }

  // If original has mixed casing, return newValue as is
  else {
    return newValue;
  }
}

/**
 * Returns true if the fundraiser has a group with a group leader name, the existance
 * of a group leader name indicates there may be grades / teachers in the group.
 *
 * @param fundraiser
 * @returns
 */
export function isGradeLike(fundraiser: Fundraiser | FundraiserDetails): boolean {
  if (!fundraiser) {
    return false;
  }

  const group_list: Group[] | undefined =
    (Object.prototype.hasOwnProperty.call(fundraiser, 'details')) ?
    (fundraiser as Fundraiser)?.details?.groups :
    (fundraiser as FundraiserDetails).groups;

  if (!group_list) {
    return false;
  }

  for (const group of group_list) {
    if (group.group_leader_name) {
      return true;
    }
  }

  return false;
}