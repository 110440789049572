import { Inject, Component } from "@angular/core";
import { InstanceConfigHolderService } from "ng-busy";

@Component({
  selector: "rc-busy",
  templateUrl: "./rc-busy.component.html",
  styleUrls: ["./rc-busy.component.css"],
})
export class RcBusyComponent {
  constructor(
    @Inject("instanceConfigHolder")
    private instanceConfigHolder: InstanceConfigHolderService
  ) {}

  get message() {
    return this.instanceConfigHolder.config.message;
  }
}
