import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "rc-footer",
  templateUrl: "./rc-footer.component.html",
  styleUrls: ["./rc-footer.component.scss"],
})
export class RcFooterComponent {
  // This shows the 'contact' row
  @Input() showContact: boolean = false;

  currentYear: number;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }
}
