import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { AppValidators } from "app/app.validators";
import { Account } from "@raisecraze/model";

@Injectable()
export class RcAccountEmailForm {
  constructor(private fb: UntypedFormBuilder, private appValidators: AppValidators) {}

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          this.appValidators.emailValidator.bind(this),
        ]),
      ],
    });
  }

  clean(formGroup: UntypedFormGroup): any {
    const formModel: any = formGroup.value;
    const account: any = {
      email: formModel.email as string,
    };

    return account;
  }

  updateForm(form: UntypedFormGroup, account: Account): void {
    const formData: any = {
      email: account.email,
    };

    form.reset(formData);
  }
}

@Injectable()
export class RcAccountPasswordForm {
  constructor(private fb: UntypedFormBuilder) {}

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      newPassword: [null, Validators.compose([Validators.required])],
      newPasswordConfirm: [null, Validators.compose([Validators.required])],
    });
  }

  clean(formGroup: UntypedFormGroup): any {
    const formModel: any = formGroup.value;
    const account: any = {
      oldPassword: formModel.oldPassword as string,
      newPassword: formModel.newPassword as string,
      newPasswordConfirm: formModel.newPasswordConfirm as string,
    };

    return account;
  }
}
