import {
  OnInit,
  OnDestroy,
  Component,
  Output,
  EventEmitter,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { AppService } from "app/app.service";
import { RcAccountActions } from "../rc-account.actions";
import { RcAccountLoginForm } from "./rc-account-login.forms";
import { IAppState } from "app/app.state";
import { SubscriptionManager } from "app/common/subscriptions/subscription.manager";
import { BreadcrumbService } from "app/breadcrumb.service";

@Component({
  selector: "rc-account-login",
  templateUrl: "./rc-account-login.component.html",
  styleUrls: ["./rc-account-login.scss"],
})
export class RcAccountLoginComponent implements OnInit, OnDestroy {
  @Output() actionType: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  busy: boolean = false;
  hasError: boolean = false;
  isCreateScreen: boolean = true;
  _subs: SubscriptionManager = new SubscriptionManager();

  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
    private appService: AppService,
    private accountActions: RcAccountActions,
    public accountAuthForm: RcAccountLoginForm,
    private breadcrumbs: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.breadcrumbs.add("component", "RcAccountLoginComponent");

    this.form = this.accountAuthForm.buildForm();

    // Form Failure
    this._subs.push(
      this.actions$
        .pipe(ofType(RcAccountActions.ACCOUNT_LOGIN_FAILED))
        .subscribe((payload) => {
          this.busy = false;
          this.hasError = true;
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribeAll();
  }

  tapLogin($event?: any): void {
    if ($event) {
      $event.preventDefault();
    }

    if (this.form.valid) {
      this.busy = true;
      const account: any = this.accountAuthForm.clean(this.form);
      this.store.dispatch(
        this.accountActions.login(account.email, account.password)
      );
    } else {
      this.appService.validateForm(this.form);
    }
  }

  tapCreate($event?: any): void {
    if ($event) {
      $event.preventDefault();
    }

    this.actionType.emit("create");
  }
}
